import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import {
  deleteTask,
  getRecommendationsCountWithCohortId,
  getTasksWithCohortId,
  handleSnooze,
  markAsComplete,
  markAsCompleteAll,
} from "../../../actions/radminUploads/index";

import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import styled from "styled-components";

import Table from "../../../components/Table/Table";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";

import DoubleDataCell from "../PatientList/LastScanCell";
import { Card } from "../../../styles";
import moment from "moment";
import { showAlert } from "../../../actions/radminUploads";

import DatePicker from "react-datepicker";
import Popover from "@material-ui/core/Popover";
import { Spinner } from "react-bootstrap";

import { ReactComponent as RemoveRecoIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { ReactComponent as RemoveRecoIconHover } from "../../../assets/delete-hover.svg";
import { ReactComponent as SnoozeIcon } from "../../../assets/snooze-icon.svg";
import { ReactComponent as SnoozeIconHover } from "../../../assets/snooze-icon-hover.svg";

import { ReactComponent as OverdueIcon } from "../../../assets/overdue-icon.svg";
import RemoveConfirmation from "./RemoveConfirmation";
import EditTask from "./EditTask";

import { ReactComponent as EditRecoIconHover } from "../../../assets/edit-icon-hover.svg";
import { ReactComponent as EditRecoIcon } from "../../../assets/editRecoIcon.svg";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";

const ApplyButton = styled.button`
  width: 100%;
  height: 38px;
  background-color: #00aeef;
  color: #ffffff;
  opacity: ${(props) => (props.disabled ? 0.65 : 1)};
  border-radius: 4px;
  border: 2px solid #00aeef;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
  &:hover {
    background: transparent;
    color: #00aeef;
  }
`;

const RadioBtn = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid;
  border-radius: 50%;
  border-color: ${(props) => props.borderColor};
  background-color: ${(props) => props.bg};
  padding: 1% !important;
  background-clip: content-box;
  margin-right: 7px;
  cursor: pointer;
`;

const RadioLabel = styled.label`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 3px;
  margin-bottom: 0px;
`;

const RadioContainer = styled.div`
  width: 247px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
`;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: "28px",
    fontWeight: 700,
    color: "#0C2945",
    fontFamily: "Roboto Condensed",
    marginRight: "40px",
  },
  accessionNumLabel: {
    fontFamily: "Roboto Condensed",
    fontSize: 12,
    lineHeight: "14.6px",
    fontWeight: 400,
    color: "#6D6E6E",
    textTransform: "uppercase",
  },
  accessionNum: {
    fontSize: 16,
    lineHeight: "21.7px",
    fontWeight: 600,
    color: "##000000",
  },
  arrow: {
    border: "2px solid #A764C6",
    backgroundColor: "#E9D8F1",
    overflowX: "unset",
    overflowY: "unset",
    boxShadow: "none",
    "&::before": {
      content: '""',
      position: "absolute",
      marginRight: "-0.71em",
      bottom: 0,
      right: 0,
      width: 18,
      height: 18,
      backgroundColor: "#E9D8F1",
      left: "0px",
      top: "50%",
      border: "2px solid #A764C6",
      transform: "translate(-50%, -50%) rotate(225deg)",
      clipPath:
        "polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))",
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  let { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <>{children}</>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: "98%",
    margin: "0 auto",
  },
}))(MuiDialogContent);

const DialogContentStyled = styled(DialogContent)``;

const SwitchContainer = styled.div`
  padding: 4px;
  width: 340px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  margin-bottom: 20px;
`;

const SwitchButton = styled.button`
  padding: 7px 35.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  color: #00aeef;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
`;

const IconContainerBox = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;

  svg {
    position: absolute;
    top: 0px;
    left: 0;
    transition: opacity 0.1s ease;
  }

  svg.dark {
    opacity: 1;
  }

  svg.light {
    opacity: 0;
  }

  &:hover svg.dark {
    opacity: 0;
  }

  &:hover svg.light {
    opacity: 1;
  }

  .label {
    display: none;
    font-size: 9px;
    font-weight: 700;
    color: ${(props) => props.color};
  }

  &:hover .label {
    display: block;
    position: absolute;
    top: ${(props) => props.startGap || "-40%"};
    line-height: 12px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const pendingTasksColumsData = [
  {
    key: "type",
    name: "Task Type",
    displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
  },
  {
    key: "due_at",
    name: "Due Date",
    displayCell: (cellValue) => (
      <DoubleDataCell
        mainInfo={moment(cellValue).format("MMMM D, YYYY [at] h:mm a")}
      />
    ),
  },
  {
    key: "patientName",
    name: "Patient",
    displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
  },
  {
    key: "patientMRN",
    name: "MRN",
    displayCell: (cellValue, row) => (
      <StyledLink
        to={`/auth/radAdmin/followUpList/patients/patientDetails/${row.patient.id}`}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
          window.analytics.track("Recommendation-Task-List-Patient-MRN-Click", {
            patientId: row.patient.id,
          });
        }}
      >
        {cellValue}
      </StyledLink>
    ),
  },
  {
    key: "assignedTo",
    name: "Assigned To",
    displayCell: (cellValue) => <DoubleDataCell mainInfo={cellValue} />,
  },
  {
    key: "priority",
    name: "Priority",
    displayCell: (cellValue) => (
      <DoubleDataCell mainInfo={capitalize(cellValue)} />
    ),
  },
];

export default function ViewRecoTask({
  openDialog,
  selectedData,
  editModalHandle,
  id,
  setCountsList,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("not_completed_tasks");
  const [page, setPage] = useState(1);

  const tasksData = useSelector(
    (state) => state.radAdmin?.cohortsRecommendations?.tasks
  );

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_cohorts"
    )?.is_authorized;

  const addPermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "add_cohorts"
    )?.is_authorized;

  const deletePermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "delete_cohorts"
    )?.is_authorized;

  useEffect(() => {
    dispatch(
      getTasksWithCohortId(
        id,
        page,
        activeTab === "not_completed_tasks" ? false : true,
        selectedData?.id
      )
    );
  }, [activeTab]);

  const onLoadMore = () => {
    if (id) {
      dispatch(
        getTasksWithCohortId(
          id,
          page + 1,
          activeTab === "not_completed_tasks" ? false : true,
          selectedData?.id
        )
      );
      setPage(page + 1);
    }
  };

  const [snoozeAnchorEl, setSnoozeAnchorEl] = React.useState(null);
  const [selectedTaskRow, setSelectedTaskRow] = useState();

  const handleClickSnooze = (event, row) => {
    setSnoozeAnchorEl(event.currentTarget);
    setSelectedTaskRow(row);
  };

  const handleCloseSnooze = () => {
    setSnoozeAnchorEl(null);
    setSelectedTaskRow();
    setSelectedOption("7 days");
    setCustomDate(null);
    setCustomOptionVisible(false);
  };

  const openSnoozePopovers = Boolean(snoozeAnchorEl);
  const snoozePopoverId = openSnoozePopovers
    ? "simple-popover-snooze"
    : undefined;

  const [selectedOption, setSelectedOption] = useState("7 days");
  const [customDate, setCustomDate] = useState(null);
  const [customOptionVisible, setCustomOptionVisible] = useState(false);
  const [loadingSnoozing, setLoadingSnoozing] = useState(false);

  const [removeTaskDialogOpen, setRemoveTaskDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isLoadingDeleteTask, setIsLoadingDeleteTask] = useState(false);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);

    if (value !== "Custom") {
    } else {
      setCustomOptionVisible(!customOptionVisible);
    }
  };

  const handleDateChange = (date) => {
    setCustomDate(date);
    setSelectedOption(date ? date.toLocaleString() : "Custom");
    setCustomOptionVisible(true);
  };

  const dialogHandlerTask = (data) => {
    setRemoveTaskDialogOpen(!removeTaskDialogOpen);
    setSelectedId(data.id);
    window.analytics.track("Task-Delete-Button-Click");
  };

  const deleteTaskHandler = () => {
    setIsLoadingDeleteTask(true);
    dispatch(
      deleteTask(
        selectedId,
        page,
        id,
        tasksData?.data?.length === tasksData?.totalItems,
        selectedData?.id
      )
    ).then((res) => {
      setIsLoadingDeleteTask(false);
      if (res) {
        setRemoveTaskDialogOpen(false);
        dispatch(getRecommendationsCountWithCohortId(id)).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
  };

  const checkboxHandler = (data, type) => {
    dispatch(
      markAsComplete(
        data.id,
        type,
        page,
        id,
        tasksData?.data?.length === tasksData?.totalItems,
        selectedData?.id
      )
    ).then((res) => {
      if (res) {
        dispatch(getRecommendationsCountWithCohortId(id)).then((res) => {
          if (res) {
            setCountsList(res);
          }
        });
      }
    });
    if (selectedIds.includes(data.id)) {
      const filteredItems = selectedIds.filter((item) => {
        return item !== data.id;
      });
      setSelectedIds([...filteredItems]);
    }
    if (!selectedIds.includes(data.id)) {
      setSelectedIds((prev) => [...prev, data.id]);
    }
  };

  const checkboxHandlerAll = (data) => {
    dispatch(markAsCompleteAll(id, [...data.map((itm) => itm.id)])).then(
      (res) => {
        if (res) {
          dispatch(getRecommendationsCountWithCohortId(id)).then((res) => {
            if (res) {
              setCountsList(res);
            }
          });
          if (
            activeTab === "not_completed_tasks" ||
            activeTab === "completed_tasks"
          ) {
            dispatch(
              getTasksWithCohortId(
                id,
                1,
                activeTab === "not_completed_tasks" ? false : true
              )
            );
            setPage(1);
          }
        }
      }
    );
    if (allSelected) {
      setSelectedIds([]);
    } else {
      setSelectedIds([...data.map((itm) => itm.id)]);
    }
    setAllSelected((prev) => !prev);
  };

  const [openEditTask, setOpenEditTask] = useState(false);
  const [openEditTaskData, setOpenEditTaskData] = useState({});

  const handleEditTask = (data) => {
    setOpenEditTask(true);
    setOpenEditTaskData(data);
  };

  return (
    <div>
      <Dialog
        fullWidth
        onClose={editModalHandle}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth="lg"
      >
        <DialogTitle
          id="customized-dialog-title"
          style={{ boxShadow: "0px 8px 10px 0px #0000001A", zIndex: 2 }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: 40,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <Typography className={classes.heading}>Task Details</Typography>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "30px",
                }}
              >
                <Typography className={classes.accessionNumLabel}>
                  Accession Number
                </Typography>
                <Typography className={classes.accessionNum}>
                  {selectedData && selectedData.accessionNumber}
                </Typography>
              </div>
            </div>

            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={editModalHandle}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContentStyled>
          <div>
            <>
              <SwitchContainer>
                <SwitchButton
                  active={activeTab === "not_completed_tasks"}
                  onClick={() => {
                    if (activeTab !== "not_completed_tasks") {
                      setActiveTab("not_completed_tasks");
                      setPage(1);
                      setSelectedIds([]);
                      setAllSelected(false);
                      window.analytics.track("Task-List-Click");
                    }
                  }}
                >
                  Task List
                </SwitchButton>
                <SwitchButton
                  active={activeTab === "completed_tasks"}
                  onClick={() => {
                    if (activeTab !== "completed_tasks") {
                      setActiveTab("completed_tasks");
                      setPage(1);
                      setSelectedIds([]);
                      setAllSelected(false);
                      window.analytics.track("Completed-Tasks-Click");
                    }
                  }}
                >
                  Completed Task List
                </SwitchButton>
              </SwitchContainer>
              <Card
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  marginTop: 0,
                }}
              >
                <Table
                  fromRecoValidation={true}
                  showOnlyLabel={activeTab === "completed_tasks" ? true : false}
                  checkBoxAtStart={false}
                  allSelected={allSelected}
                  selectedIds={selectedIds}
                  selectAllCb={(rows, e) => {
                    e.stopPropagation();
                    if ((permission && updatePermission) || !permission) {
                      checkboxHandlerAll(rows);
                    } else {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `You don't have permission to update task`
                        )
                      );
                    }
                  }}
                  shouldGetRowActions={false}
                  rows={tasksData?.data}
                  columns={
                    activeTab === "not_completed_tasks"
                      ? [
                          {
                            key: "",
                            name: "",
                            displayCell: (cellValue, rowData) => (
                              <div style={{ display: "flex", gap: "20px" }}>
                                <IconContainerBox color="#643268">
                                  <EditRecoIcon
                                    className="dark"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        (permission && addPermission) ||
                                        !permission
                                      ) {
                                        handleEditTask(rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to edit task`
                                          )
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <EditRecoIconHover
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        (permission && addPermission) ||
                                        !permission
                                      ) {
                                        handleEditTask(rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to edit task`
                                          )
                                        );
                                      }
                                    }}
                                    className="light"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContainerBox>

                                <IconContainerBox color="#643268">
                                  <RemoveRecoIcon
                                    className="dark"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        (permission && deletePermission) ||
                                        !permission
                                      ) {
                                        dialogHandlerTask(rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to delete task`
                                          )
                                        );
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                  <RemoveRecoIconHover
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (
                                        (permission && deletePermission) ||
                                        !permission
                                      ) {
                                        dialogHandlerTask(rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to delete task`
                                          )
                                        );
                                      }
                                    }}
                                    className="light"
                                    style={{ cursor: "pointer" }}
                                  />
                                </IconContainerBox>
                                {moment().isAfter(rowData.due_at) ? (
                                  <>
                                    <IconContainerBox color="#643268">
                                      <OverdueIcon
                                        className="dark"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (
                                            (permission && updatePermission) ||
                                            !permission
                                          ) {
                                            handleClickSnooze(e, rowData);
                                          } else {
                                            dispatch(
                                              showAlert(
                                                "danger",
                                                "Error",
                                                `You don't have permission to update task`
                                              )
                                            );
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                      <OverdueIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (
                                            (permission && updatePermission) ||
                                            !permission
                                          ) {
                                            handleClickSnooze(e, rowData);
                                          } else {
                                            dispatch(
                                              showAlert(
                                                "danger",
                                                "Error",
                                                `You don't have permission to update task`
                                              )
                                            );
                                          }
                                        }}
                                        className="light"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </IconContainerBox>
                                  </>
                                ) : (
                                  <>
                                    <IconContainerBox color="#643268">
                                      <SnoozeIcon
                                        className="dark"
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          if (
                                            (permission && updatePermission) ||
                                            !permission
                                          ) {
                                            handleClickSnooze(e, rowData);
                                          } else {
                                            dispatch(
                                              showAlert(
                                                "danger",
                                                "Error",
                                                `You don't have permission to update task`
                                              )
                                            );
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                      <SnoozeIconHover
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          if (
                                            (permission && updatePermission) ||
                                            !permission
                                          ) {
                                            handleClickSnooze(e, rowData);
                                          } else {
                                            dispatch(
                                              showAlert(
                                                "danger",
                                                "Error",
                                                `You don't have permission to update task`
                                              )
                                            );
                                          }
                                        }}
                                        className="light"
                                        style={{ cursor: "pointer" }}
                                      />
                                    </IconContainerBox>
                                  </>
                                )}
                              </div>
                            ),
                            enableCheckBox: false,
                            align: "left",
                          },
                        ].concat(pendingTasksColumsData, {
                          key: "",
                          name: "",
                          displayCell: (cellValue, rowData) => (
                            <div style={{ display: "flex", gap: "20px" }}>
                              <DoubleDataCell
                                mainInfo={"checkbox"}
                                checkboxHandler={(e) => {
                                  e.stopPropagation();
                                  if (
                                    (permission && updatePermission) ||
                                    !permission
                                  ) {
                                    checkboxHandler(rowData, "complete");
                                  } else {
                                    dispatch(
                                      showAlert(
                                        "danger",
                                        "Error",
                                        `You don't have permission to update task`
                                      )
                                    );
                                  }
                                }}
                                selectedIds={selectedIds}
                                checked={selectedIds?.includes(rowData?.id)}
                              />
                            </div>
                          ),
                          enableCheckBox: true,
                          align: "left",
                        })
                      : [
                          {
                            key: "",
                            name: "",
                            displayCell: (cellValue, rowData) => (
                              <div
                                style={{ display: "flex", gap: "20px" }}
                              ></div>
                            ),
                            enableCheckBox: false,
                            align: "left",
                          },
                        ].concat(pendingTasksColumsData, {
                          key: "",
                          name: "",
                          displayCell: (cellValue, rowData) => (
                            <div style={{ display: "flex", gap: "20px" }}>
                              <DoubleDataCell
                                mainInfo={"checkbox"}
                                checkboxHandler={(e) => {
                                  e.stopPropagation();
                                  if (
                                    (permission && updatePermission) ||
                                    !permission
                                  ) {
                                    checkboxHandler(rowData, "incomplete");
                                  } else {
                                    dispatch(
                                      showAlert(
                                        "danger",
                                        "Error",
                                        `You don't have permission to update task`
                                      )
                                    );
                                  }
                                }}
                                selectedIds={selectedIds}
                                checked={true}
                              />
                            </div>
                          ),
                          enableCheckBox: true,
                          align: "left",
                        })
                  }
                  initialPageNumber={tasksData?.pageNumber}
                  totalItems={tasksData?.totalItems}
                  isLoadingRows={
                    tasksData?.isLoading && tasksData?.pageNumber < 2
                  }
                  getRowsAction={(pageNumber) =>
                    getTasksWithCohortId(
                      id,
                      page,
                      activeTab === "not_completed_tasks" ? false : true,
                      selectedData?.id
                    )
                  }
                  noDataRender={() => (
                    <EmptyState
                      title={"No Results"}
                      img={() => <EmptyStateImg />}
                    >
                      Sorry, there are no results
                    </EmptyState>
                  )}
                />
                {tasksData?.data && tasksData?.data?.length !== 0 && (
                  <LoadMoreButton
                    title="Load more tasks"
                    handleClick={onLoadMore}
                    isDisabled={
                      tasksData?.data?.length === tasksData?.totalItems
                    }
                    isLoading={tasksData?.isLoading}
                    style={{
                      backgroundColor: "#0c2945",
                      fontWeight: 600,
                    }}
                  />
                )}
              </Card>
            </>{" "}
          </div>
        </DialogContentStyled>
      </Dialog>
      {openSnoozePopovers && (
        <Popover
          classes={{ paper: classes.arrow }}
          id={snoozePopoverId}
          open={openSnoozePopovers}
          anchorEl={snoozeAnchorEl}
          onClose={handleCloseSnooze}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: -20,
          }}
        >
          <div
            style={{
              padding: "6px 12px 11px 12px",
            }}
          >
            <p style={{ fontSize: "12px", fontWeight: 600 }}>Snooze until...</p>
            <RadioContainer>
              {!moment().isAfter(
                moment(selectedTaskRow.due_at).add(7, "days")
              ) && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="7 days"
                    checked={selectedOption === "7 days"}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === "7 days" ? "#00AEEF" : "#A764C6"
                    }
                    bg={selectedOption === "7 days" ? "#00AEEF" : "transparent"}
                  />
                  7 days
                </RadioLabel>
              )}
              {!moment().isAfter(
                moment(selectedTaskRow.due_at).add(30, "days")
              ) && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="30 days"
                    checked={selectedOption === "30 days"}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === "30 days" ? "#00AEEF" : "#A764C6"
                    }
                    bg={
                      selectedOption === "30 days" ? "#00AEEF" : "transparent"
                    }
                  />
                  30 days
                </RadioLabel>
              )}
              {!moment().isAfter(
                moment(selectedTaskRow.due_at).add(90, "days")
              ) && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value="90 days"
                    checked={selectedOption === "90 days"}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === "90 days" ? "#00AEEF" : "#A764C6"
                    }
                    bg={
                      selectedOption === "90 days" ? "#00AEEF" : "transparent"
                    }
                  />
                  90 days
                </RadioLabel>
              )}

              {customOptionVisible && customDate && (
                <RadioLabel>
                  <RadioBtn
                    type="radio"
                    value={customDate.toLocaleString()}
                    checked={selectedOption === customDate.toLocaleString()}
                    onChange={handleOptionChange}
                    borderColor={
                      selectedOption === customDate.toLocaleString()
                        ? "#00AEEF"
                        : "#A764C6"
                    }
                    bg={
                      selectedOption === customDate.toLocaleString()
                        ? "#00AEEF"
                        : "transparent"
                    }
                  />
                  {customDate.toLocaleString()}
                </RadioLabel>
              )}

              <RadioLabel>
                <RadioBtn
                  type="radio"
                  value="custom"
                  checked={selectedOption === "custom"}
                  onChange={handleOptionChange}
                  borderColor={
                    selectedOption === "custom" ? "#00AEEF" : "#A764C6"
                  }
                  bg={selectedOption === "custom" ? "#00AEEF" : "transparent"}
                />
                Custom Date & Time
              </RadioLabel>
            </RadioContainer>

            {selectedOption === "custom" && (
              <DatePicker
                selected={customDate}
                onChange={handleDateChange}
                showTimeSelect
                dateFormat="Pp"
                placeholderText="Select date and time"
                inline
                minDate={new Date()}
              />
            )}

            <ApplyButton
              disabled={!selectedOption || selectedOption === "custom"}
              onClick={() => {
                setLoadingSnoozing(true);
                dispatch(
                  handleSnooze(
                    selectedTaskRow.id,
                    selectedOption === "7 days"
                      ? moment(selectedTaskRow.due_at).add(7, "days")
                      : // .format("YYYY-MM-DDTHH:mm:ss")
                      selectedOption === "30 days"
                      ? moment(selectedTaskRow.due_at).add(30, "days")
                      : // .format("YYYY-MM-DDTHH:mm:ss[Z]")
                      selectedOption === "90 days"
                      ? moment(selectedTaskRow.due_at).add(90, "days")
                      : // .format("YYYY-MM-DDTHH:mm:ss[Z]")
                        moment(customDate.toLocaleString())
                    // .format(
                    //     "YYYY-MM-DDTHH:mm:ss[Z]"
                    //   )
                  )
                ).then((res) => setLoadingSnoozing(false));
                handleCloseSnooze();
              }}
            >
              {loadingSnoozing ? (
                <Spinner size="sm" animation="border" role="status" />
              ) : (
                "Apply"
              )}
            </ApplyButton>
          </div>
        </Popover>
      )}
      <RemoveConfirmation
        content="Are you sure you want to remove this task?"
        openDialog={removeTaskDialogOpen}
        dialogHandler={dialogHandlerTask}
        isLoading={isLoadingDeleteTask}
        deleteHandler={deleteTaskHandler}
        heading={"Remove Task"}
        removeHeader={"Yes, Remove Task"}
      />

      {openEditTask && (
        <EditTask
          openDialog={openEditTask}
          editModalHandle={() => {
            setOpenEditTask(false);
            setOpenEditTaskData({});
          }}
          selectedData={openEditTaskData}
          setCountsList={setCountsList}
          cohortId={id}
        />
      )}
    </div>
  );
}
