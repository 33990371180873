import React from "react";
import styled from "styled-components";

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const ParentSeparator = styled.div`
  font-weight: 400;
`;

const ChildGroup = styled.div`
  padding: 5px 10px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  flex: 1;
  display: flex;
  gap: 8px;
`;

const ChildChip = styled.span`
  padding: 4px 10px;
  border: none;
  border-radius: 25px;
  font-weight: 400;
  white-space:nowrap;
  background: ${(props) => props.backgroundColor};
`;

const DescriptionChipGroups = ({ parents, operators }) => {
  console.log('parents, operators', parents, operators)
  return parents.map((parent, index) => (
    <ParentContainer key={index}>
      <ChildGroup>
        {parent
          .replace(/^\[|\]$/g, "")
          .split(/(?<!AND|OR)\s*(AND|OR)\s*(?!AND|OR)/)
          .map((item, i) => {
            const isOperator = item.trim() === "AND" || item.trim() === "OR";
            return (
              <ChildChip
                key={i}
                backgroundColor={isOperator ? "transparent" : "#EEEEEE"}
              >
                {item.trim().replace(/^\(|\)$/g, "")}
              </ChildChip>
            );
          })}
      </ChildGroup>

      {index < operators.length && (
        <ParentSeparator>{operators[index]}</ParentSeparator>
      )}
    </ParentContainer>
  ));
};

export default DescriptionChipGroups;
