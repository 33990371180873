import React, { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import Select, { components } from "react-select";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import cioEvent from "../../../assets/cio_icon.svg";
import {
  getAllReportCohorts,
  getRecoCohortsDropdownOptions,
  getReportCohorts,
} from "../../../actions/radminUploads";
import { useEffect } from "react";
import Creatable from "react-select/creatable";

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 344px !important;
  padding: 18px 24px !important;
  height: fit-content !important;

  margin: 15% auto;
  background: white;

  @media (max-width: 768px) {
    width: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

const CheckBoxLabel = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 15px;
`;

const CheckboxTitle = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-align: left;
  text-transform: uppercase;
`;

const Label = styled.label`
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3f4857;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  height: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
`;

export const Image = styled.img`
  width: 20px;
  margin-left: 7px;
  color: #000000;
  fill: #000000;
`;

const Button = styled.button`
  width: 140px;
  height: 38px;
  background: ${({ isPrimary, disabled }) =>
    disabled ? "#00AEEF4D" : isPrimary ? "#00AEEF" : "transparent"};
  border-radius: 5px;
  border: ${({ isPrimary, disabled }) =>
    disabled
      ? "2px solid transparent"
      : isPrimary
      ? "2px solid #00AEEF"
      : "2px solid #CFCFCF"};
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isPrimary }) => (isPrimary ? "white" : "#000000")};
  &:hover {
    background: ${({ isPrimary }) => (isPrimary ? "transparent" : "#CFCFCF")};
    color: ${({ isPrimary }) => (isPrimary ? "#00aeef" : "white")};
    border: ${({ isPrimary, disabled }) =>
      isPrimary ? "2px solid #00AEEF" : "2px solid #CFCFCF"};
  }
`;

const customStyle = {
  overlay: {
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(12, 41, 69, 0.38)",
    zIndex: 1,
  },
  content: {
    borderRadius: "4px",
  },
};



const IndicatorsContainer = (props) => {
  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};
const colourStyles = {
  placeholder: (styles) => ({ ...styles, fontSize: "14px" }),
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { isDisabled, isSelected }) => {
    return {
      ...styles,
      fontSize: "14px",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
      backgroundColor: isSelected ? "#D9ECFF" : "white",
      display: "flex",
    };
  },
  multiValue: (styles, { data }) => ({
    ...styles,
    borderRadius: "25px",
    backgroundColor: "#EEEEEE",
    color: "#A1A1A1",
    padding: "4px 10px",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#A1A1A1",
    fontSize: "10px",
  }),
};

const CustomModal = ({
  headerTitle = "",
  inputTitle = "",
  placeholderText = "",
  onClose,
  onSave,
  opened,
  inputValue,
  setInputValue,
  showDropDown,
  isChecked,
  setIsChecked,
}) => {
  const [loading, setLoading] = useState(false);
  const [savedCohortGroups, setSavedCohortGroups] = useState();
  const dispatch = useDispatch();

  const [selectedGroup, setSelectedGroup] = useState();

    const hasReportSearchEventsEnabled =  useSelector((state) => state.auth.user.provider.report_search_event);
  
    
const RadioOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div style={{display:'flex', gap:'5px'}}>
        <span >
          <input
            type="radio"
            checked={props.isSelected}
            onChange={() => null}
            style={{ marginTop: 5 }}
          />{" "}
        </span>
        <label>{props.label}
       
        </label>
        {props?.data?.trigger_cio_event && hasReportSearchEventsEnabled ? (
          <span>
            <Image src={cioEvent} alt={"settings icon"} />
          </span>
        ) : null}
        </div>
      
      </components.Option>
    </>
  );
};


  const handleFilterSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    onSave(selectedGroup);
    setLoading(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    dispatch(getAllReportCohorts()).then((res) => {
      if (res) {
        setSavedCohortGroups(
          res?.results?.map((itm) => ({
            ...itm,
            label: itm.title,
            value: itm.id,
          }))
        );
      }
    });
  }, []);

  const handleChangeAnatomy = (newValue, actionMeta) => {
    if (actionMeta.action === "create-option") {
      const newOption = { value: newValue.value, label: newValue.label };
      setSavedCohortGroups((prevOptions) => [...prevOptions, newOption]);
    }
    setSelectedGroup(newValue);
    setInputValue(newValue?.value);

    if(newValue?.trigger_cio_event){
      setIsChecked(true);
    }else{
      setIsChecked(false);

    }
  };

  return (
    <Modal
      style={customStyle}
      isOpen={opened}
      onRequestClose={onClose}
      contentLabel="Save Filter Set"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h2
          style={{
            fontSize: "24px",
            color: "#0C2945",
            fontWeight: 700,
            fontFamily: "Roboto Condensed",
          }}
        >
          {headerTitle}
        </h2>
        <p style={{ cursor: "pointer" }} onClick={onClose}>
          x
        </p>
      </div>
      <form style={{ width: "100%" }} onSubmit={handleFilterSave}>
        <div>
          {showDropDown ? (
            <>
              <div style={{ minWidth: "270px" }}>
              <Label children="Select Cohort" />
                   <Creatable
                                           isClearable={true}
                                           onChange={handleChangeAnatomy}

                                           options={savedCohortGroups}
                                           value={selectedGroup}
                                           placeholder="Type or Select..."
                                           components={{
                                             IndicatorsContainer,
                                             Option: RadioOption,
                                           }}
                                           className="basic-multi-select"
                                           classNamePrefix="select"
                                           styles={{
                                             option: (styles) => ({
                                               ...styles,
                                               fontSize: "14px",
                                             }),
                                           }}
                                         />
              
              </div>
              {/* <div style={{ minWidth: "270px" }}> */}
                {/* <Label children="Select Cohort" /> */}

                {/* <Select
                  components={{
                    Option,
                    IndicatorsContainer,
                  }}
                  id="customChartDropdown"
                  options={savedCohortGroups}
                  value={selectedGroup}
                  onChange={(value) => {
                    setSelectedGroup(value);
                    setInputValue(value?.title);
                  }}
                  isLoading={loadingMore}
                  onMenuScrollToBottom={loadMoreOptions}
                  isClearable={true}
                  styles={colourStyles}
                /> */}
              {/* </div> */}
            </>
          ) : (
            <div>
              <Label children={inputTitle} htmlFor="filterSet" />
              <Input
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                id="filterSet"
                placeholder={placeholderText}
              />
            </div>
          )}
          {
            hasReportSearchEventsEnabled &&    <div style={{ marginTop: "20px" }}>
            <CheckBoxLabel>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <CheckboxTitle>Add to Messaging Campaign</CheckboxTitle>
            </CheckBoxLabel>
          </div>
          }
        
        </div>

        <ButtonContainer>
          <Button onClick={onClose} children="Cancel" />
          <Button
            type="submit"
            isPrimary={true}
            disabled={!inputValue && !selectedGroup}
            children={
              loading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Save"
              )
            }
          />
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default CustomModal;
