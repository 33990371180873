export const parseQuery = (query) => {
    const parentRegex = /\[(.*?)\]/g; 
    const operatorRegex = /\]\s*(AND|OR)\s*\[/g; 
    
    const parents = query?.match(parentRegex) || [];
    const operators = (query?.match(operatorRegex) || [])?.map((op) =>
      op.replace(/\]|\[/g, "").trim()
    );
    return { parents, operators };
  };
