import React, { useState } from "react";
import Box from "../../../components/_styles/Box";
import { Card, PageTitle } from "../../../styles";
import CohortList from "./index";
import styled from "styled-components";
import { Button, makeStyles } from "@material-ui/core";
import { ReactComponent as SearchIconCustom } from "../../../assets/searchIconGrey.svg";
import { useHistory } from "react-router-dom";
import {
  getCohorts,
  getRecoCohorts,
  getReportCohorts,
  showAlert,
} from "../../../actions/radminUploads";
import { useDispatch, useSelector } from "react-redux";
import SearchField from "../SharedComponents/SearchField";
import { HeaderContainer } from "../Cohorts/Create";
import CohortRecommendationList from "../RecommendationCohorts/index";
import CohortPatientList from "../Cohorts/index";

const SwitchContainer = styled.div`
  // padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width: fit-content;
  margin-top: 30px;
`;

const SearchFieldContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: right;
  height: 38px;
  margin-right: 17px;
`;

const SwitchButton = styled.button`
  padding: 7px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;
const Headercontainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  campaignButton: {
    backgroundColor: "#00AEEF",
    textTransform: "none",
    width: "8rem",
    height: "38px",
    color: "#FFFFFF",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
  },
});

const tabOption = [
  { label: "Recommendations", value: "recommendations" },
  { label: "Reports", value: "reports" },
  { label: "Patients", value: "patients" },
];
const CohortTab = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(props.location.search);
  const tabFromUrl = queryParams.get("tab");
  const [activeTab, setActiveTab] = useState(tabFromUrl ?? "recommendations");
  const [page, setPage] = useState(1);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [titleForSearch, setTitleForSearch] = useState("");
  const [isLoadingDeleteCohort, setIsLoadingDeleteCohort] = useState(false);

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const createPermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "add_cohorts"
    )?.is_authorized;

  const createCohortReportHandler = () => {
    if (activeTab === "reports") {
      if ((permission && createPermission) || !permission) {
      history.push(`/auth/radAdmin/followUp/reports/cohorts/create`, {
        data: activeTab, 
      });
      window.analytics.track("Cohort-Report-Create-Button-Click");
     }else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to create reports cohorts`
        )
      );
    }
    } else if (activeTab === "recommendations") {
      if ((permission && createPermission) || !permission) {
        history.push(`/auth/radAdmin/followUp/recommendations/cohorts/create`, {
          data: activeTab,
        });
        window.analytics.track("Recommendations-Cohort-Create-Button-Click");
      } else {
        dispatch(
          showAlert(
            "danger",
            "Error",
            `You don't have permission to create recommendation cohorts`
          )
        );
      }
    } else if (activeTab === "patients") {
      if ((permission && createPermission) || !permission) {
        history.push(`/auth/radAdmin/followUp/patients/cohorts/create`, {
          data: activeTab,
        });
        window.analytics.track("Patient-Cohort-Create-Button-Click");
      } else {
        dispatch(
          showAlert(
            "danger",
            "Error",
            `You don't have permission to create patient cohorts`
          )
        );
      }
    }
  };
  return (
    <Box p={30}>
      <HeaderContainer>
        <PageTitle>Cohorts</PageTitle>
        <SearchFieldContainer>
          <SearchField
            placeholder="Search Cohort Title"
            value={titleForSearch}
            onChangeHandle={(e) => {
              setTitleForSearch(e.target.value);
              history.push({
                search: `&tab=${activeTab}&title=${e.target.value}`,
              });
            }}
            onReset={() => {
              if (titleForSearch) {
                if (activeTab === "reports") {
                  dispatch(getReportCohorts(1, ""));
                }
                if (activeTab === "recommendations") {
                  dispatch(getRecoCohorts(1, ""));
                }
                if (activeTab === "patients") {
                  dispatch(dispatch(getCohorts(1, "")));
                }
                setPage(1);
                setTitleForSearch("");
                history.push({
                  ssearch: `?&tab=${activeTab}&title=`,
                });
              }
            }}
            onSearchEnter={() => {
              if (activeTab === "reports") {
                dispatch(getReportCohorts(null, titleForSearch));
              }
              if (activeTab === "recommendations") {
                dispatch(getRecoCohorts(null, titleForSearch));
              }
              if (activeTab === "patients") {
                dispatch(getCohorts(null, titleForSearch));
              }
              setPage(1);
            }}
            styles={{
              marginRight: 0,
              marginBottom: 0,
              width: "318px",
              position: "relative",
            }}
            placeholderStyles={{
              color: "#828282",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Open Sans",
              marginLeft: "32px",
            }}
            inputStyles={{
              background: "#FFFFFF",
              border: "1px solid #D2D3D7",
              textAlign: "left",
              paddingLeft: "20px",
              height: "38px",
            }}
            searchIconStyles={{
              marginLeft: "12px",
            }}
            clearIconStyle={{
              right: 20,
              marginLeft: 0,
            }}
            SearchIconCustom={<SearchIconCustom />}
          />
        </SearchFieldContainer>
        <div>
          <Button
            classes={{ label: classes.label, root: classes.campaignButton }}
            onClick={createCohortReportHandler}
          >
            Create Cohort
          </Button>
        </div>
      </HeaderContainer>
      <SwitchContainer>
        {tabOption.map((tab) => (
          <SwitchButton
            active={activeTab === tab.value}
            onClick={() => {
              window.analytics.track(`Cohorts-${tab.label}-Click`);
              setActiveTab(tab.value);
              setTitleForSearch("");
              setPage(1);
              history.push({
                pathname: history.location.pathname,
                search: `?tab=${tab.value}`,
              });
            }}
          >
            {tab.label}
          </SwitchButton>
        ))}
      </SwitchContainer>
      <Box>
        {activeTab === "reports" && (
          <CohortList
            activeTab={activeTab}
            titleForSearch={titleForSearch}
            setTitleForSearch={setTitleForSearch}
            page={page}
            setPage={setPage}
          />
        )}
        {activeTab === "recommendations" && (
          <CohortRecommendationList
            activeTab={activeTab}
            titleForSearch={titleForSearch}
            setTitleForSearch={setTitleForSearch}
            page={page}
            setPage={setPage}
          />
        )}
        {activeTab === "patients" && (
          <CohortPatientList
            activeTab={activeTab}
            titleForSearch={titleForSearch}
            setTitleForSearch={setTitleForSearch}
            page={page}
            setPage={setPage}
          />
        )}
      </Box>
    </Box>
  );
};

export default CohortTab;
