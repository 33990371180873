import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import {
  getRecentIncomingMessages,
  getTwilioConfig,
  toggleConversation,
  getReportByPatientId,
  getRecommendationByPatientId,
  changeActionStatusActionPatientDetails,
  showAlert,
  getRecentIncomingMessagesChats,
} from "../../../actions/radminUploads";
import Flex from "../../../components/_styles/Flex";
import MessageForm from "./MessageForm";
import Message from "./Message";
import MessagePreview from "./MessagePreview";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateMessageImg } from "../../../assets/empty-message.svg";
import { ReactComponent as Phone } from "../../../assets/phone-filled-grey.svg";
import { ReactComponent as ConversationCloseIcon } from "../../../assets/conversationClose.svg";
import { ReactComponent as ConversationEditIcon } from "../../../assets/conversationEdit.svg";
import EnableFeature from "../../../components/EnableFeature/EnableFeature";
import DefaultResponsesModal from "./DefaultResponsesModal";
import CloseConversationDialog from "./CloseConversationDialog";
import { CircularProgress, Popover } from "@material-ui/core";
import ActionStatusList from "../../RadAdmin/FollowUpList/ActionStatusList";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../../assets/dropdown-outline-white.svg";
import searchIcon from "../../../assets/search.svg";
import loadMoreIcon from "../../../assets/load-more.svg";
import Table from "../../../components/Table/Table";
import { TemplateButton } from "./SmsCreationDialog";
import { InfoIconOutline as InfoButtonIcon } from "./Icons";
import ViewReportModal from "./ViewReportModal";
import { LoadMoreContainer } from "../../../components/Dialog/EditFormBottom";
import { ReactComponent as StatusIcon } from "../../../assets/statusIcon.svg";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import { MessagesFilter } from "./MessagesFilter";
import close from "../../../assets/cancel-outline-chip.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import Main from "./IncommingMessages";
import FaxRecords from "./IncommingMessages/FaxRecords";

const FilterButton = styled(Flex)`
  width: 41px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00aeef;
  background: #fff;
  cursor: pointer;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
`;

const Image = styled.img`
  display: block;
  width: 20px;
`;

const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 1;
  &:hover {
    cursor: pointer;
  }
`;

const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

const SearchFilterRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
`;

const SearchBoxContainer = styled.div`
  position: relative;
  width: 100%;
`;
const SearchBox = styled.input`
  font-size: 14px;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #dfe0eb;
  padding: 9px 14px 9px 39px;
  border-radius: 5px;
  height: 38px;
`;
const ChipsContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 6px;
  flex-wrap: wrap;
  width: 100%;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  height: 100%;
`;

const IncomingMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // flex-grow: 1;
  min-width: 400px;
  width: 400px;
  // margin-left: 15px;
  overflow: auto;
  background-color: #f9fafc;
  border-right: 1px solid #c2ccd9;
  padding-left: 29px;
  padding-right: 29px;
`;

const IncomingMessagesTitle = styled.h2`
  margin-top: 15px;
  text-align: center;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;
  color: #0c2945;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PhoneNumberLink = styled.a`
  font-family: Roboto Condensed;
  line-height: 18px;
  font-size: 14px;
  text-decoration: none;
  color: #3f4857;
`;

const PhoneIcon = styled(Phone)`
  margin-right: 10px;
`;

const Info = styled.p`
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0 8px 0 0;
`;

const RecentMessagesContainer = styled.div`
  margin-top: 25px;
  padding-bottom: 34px;
  // height: 1rem;
  width: 100%;
`;

const ChatContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 2;
  height: 100%;
  border-right: 1px solid #c2ccd9;
`;

const ChatNameContainer = styled.div`
  min-height: 70px;
  max-height: 70px;
  width: 100%;
  position: absolute;
  top: 0;
  border-bottom: 1px solid #c2ccd9;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f9fafc;
`;

const ChatName = styled.span`
  padding: 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-decoration: underline;
  // display: flex;
  // align-items: center;
  color: #00aeef;
  cursor: pointer;
  margin-bottom: 0px;
`;

const ChatMessagesContainer = styled.div`
  // position: relative;
  display: flex;
  flex-direction: column-reverse;
  margin-top: 70px;
  padding: 0 30px;
  padding-top: 30px;
  height: 750px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  overflow-y: scroll;

  & .infinite-scroll-component__outerdiv {
    position: relative;
  }
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.inbound ? "flex-start" : "flex-end")};
`;

const StatusesContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
  // padding-bottom: 9px;
`;

const AdditionalInfoContainer = styled.div`
  flex-grow: 1;
  max-width: 650px;
  margin-top: 20px;
`;

const AdditionalInfo = styled.div`
  display: inline;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 70px;
  border-bottom: 1px solid #c2ccd9;
`;

const ButtonsContainer = styled.div`
  display: inline;
  align-items: left;
  justify-content: center;
  // margin:${(props) => (props.topMargin ? "260px 0 0 0" : "40px 0 0 0")}
`;

const ContainerBox = styled.div`
  padding: 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const ViewReportButton = styled.button`
  margin: 0 10px 10px;
  width: 240px;
  height: 40px;
  background-color: #00aeef;
  opacity: ${(props) => (props.disabled ? "0.67" : "1")};
  border: 0;
  border-radius: 5px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  cursor: ${(props) => (props.disabled ? "none" : "pointer")};
`;

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 600;
  font-family: Open Sans;
  white-space: nowrap;
  padding: 5px 16px 5px 16px;
  color: ${(props) => (props.status === "non_mature" ? "black" : "white")};
  background: ${(props) => {
    switch (props.status) {
      case "coming_due":
        return "#FFD57C";
      case "acknowledged":
        return "#00AEEF";
      case "overdue":
        return "#F73B3B";
      case "unsubscribed":
        return "#F73B3B";
      case "unresponsive":
        return "#808080";
      case "needs_prior_auth":
        return "#DD6ECD";
      case "needs_prescription":
        return "#FF5F65";
      case "non_compliant":
        return "#6f42c1";
      case "rx_received":
        return "#186EF0";
      case "navigator_closed":
        return "#A7A9AD";
      case "completed_elsewhere":
        return "#6F52ED";
      case "patient_deceased":
        return "#2E2F30";
      case "scheduled_not_completed":
        return "#FF7A00";
      case "not_clinically_necessary":
        return "#FFD57C";
      case "adherent":
        return "#64C67C";
      case "non_mature":
        return "2px solid #DFE0EB";
      case "expired":
        return "2px solid #6F42C1";
      case "Not available":
        return "none";
      default:
        return "#00AEEF";
    }
  }};
`;

const EditDefaultResponsesButton = styled(ViewReportButton)`
  background-color: #00aeef;
  border: none;
  color: #ffffff;
  margin-bottom: 0px;
  width: 30%;
`;

const CopyLinkButton = styled.span`
  color: #00aeef;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
`;

const CloseConversation = styled(ViewReportButton)`
  background-color: #ffffff;
  border: 2px solid #00aeef;
  color: #00aeef;
  margin-bottom: 0px;
  width: 30%;
`;
const OpenConversation = styled(ViewReportButton)`
  background-color: #ffffff;
  border: 2px solid #00aeef;
  color: #00aeef;
  margin-bottom: 0px;
  width: 30%;
`;

const OutgoingMessageButton = styled(ViewReportButton)`
  background-color: #00aeef;
  width: 200px;
  margin: 0;
`;

const MessageFormContainer = styled.div`
  height: 100px;
  font-family: "Roboto Condensed", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
`;

const Loader = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  width: 30px !important;
  height: 30px !important;
`;

const Loader2 = styled(CircularProgress)`
  width: 30px !important;
  height: 30px !important;
`;

const SeeOutgoing = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #0c2945;
  padding: 20px;
`;
const MessagesContainer = styled.div`
  height: 33rem;
  overflow: auto;
  padding-right: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const SwitchContainer = styled.div`
  padding: 4px;
  width: 100%;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
`;

const SwitchButton = styled.button`
  padding: 7px 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#FFFFFF" : "#000000")};
  border: 0;
  outline: 0;
  border-radius: 6px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const ReportsHeader = styled.h4`
  color: #0c2945;
  font-size: 24px;
  font-weight: 700;
`;
const ReportContainer = styled.div`
  padding: 20px;
  min-height: 350px;
  border-top: 1px solid #c2ccd9;
  & table td {
    padding-right: 10px;
    padding-left: 0px;
  }
  & table td:last-child {
    padding-right: 0px;
  }
  & table td:first-child {
    padding-left: 0px;
  }
`;
const LoadMoreContainerBox = styled.div`
  margin: 20px 0px;
`;

const ChatMessageLoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InfiniteScrollLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
`;

const reportColumns = [
  {
    key: "accessionNumber",
    name: "Accession Number",
  },
  {
    key: "modality",
    name: "Modality",
  },
  {
    key: "initialExamDate",
    name: "Exam Date",
  },
];

const recommendationColumns = [
  {
    key: "accessionNumber",
    name: "Accession Number",
  },
  {
    key: "modality",
    name: "Modality",
  },
  {
    key: "anatomy",
    name: "Anatomy",
  },
  {
    key: "rangeStart",
    name: "Min Due Date",
  },
  {
    key: "rangeEnd",
    name: "Max Due Date",
  },
];

export const StatusBadge = ({
  status,
  onClick,
  styles = {},
  dropDown = true,
}) => (
  <Badge onClick={onClick} status={status} style={styles}>
    {status?.replaceAll("_", " ")}
    {!dropDown &&
      (status === "adherent" ||
        status === "scheduled_not_completed" ||
        status === "rx_received") && (
        <StatusIcon style={{ marginLeft: "10px" }} />
      )}
    {dropDown && <DownArrow style={{ marginLeft: "10px" }} />}{" "}
  </Badge>
);

const Messages = () => {
  const recentMessages = useSelector(
    (state) => state.radAdmin.messages.recentMessages.data
  );
  const recentMessagesTotalItems = useSelector(
    (state) => state.radAdmin.messages.recentMessages.totalItems
  );
  const isLoadingRecentMessages = useSelector(
    (state) => state.radAdmin.messages.recentMessages.isLoading
  );

  const chatMessages = useSelector(
    (state) => state.radAdmin.messages.chatMessages.data
  );
  const isLoadingChatMessages = useSelector(
    (state) => state.radAdmin.messages.chatMessages.isLoading
  );
  const chatMessagesTotalItems = useSelector(
    (state) => state.radAdmin.messages.chatMessages.totalItems
  );

  const usersInfo = useSelector(
    (state) => state.radAdmin.messages.usersInfo.data
  );
  const providerPhoneNumber = useSelector(
    (state) => state.radAdmin.messages.twilioConfig.data?.phone_number
  );
  const dispatch = useDispatch();
  const reports = useSelector(
    (state) => state.radAdmin.patientDetails.reports?.data
  );
  const isLoadingReports = useSelector(
    (state) => state.radAdmin.patientDetails.reports.isLoading
  );
  const totalReports = useSelector(
    (state) => state.radAdmin.patientDetails.reports?.totalItems
  );
  const [reportsPage, setReportsPage] = useState(1);

  const recommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations?.data
  );
  const isLoadingRecommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations.isLoading
  );
  const totalRecommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations?.totalItems
  );
  const provider = useSelector((state) => state.auth.user.providers[0]);

  const incommingFax = useSelector((state) => state.radAdmin.faxes.faxResponse);
  const [recommendationsPage, setRecommendationsPage] = useState(1);

  const [currentChatNumber, setCurrentChatNumber] = useState();
  const [showDefaultResponsesModal, setShowDefaultResponsesModal] =
    useState(false);

  const [showCloseConvDialog, setShowCloseConvDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({
    visibility: false,
    data: {},
  });

  const location = useLocation();

  const history = useHistory();
  const node = useRef();
  const [page, setPage] = useState(1);
  const [isActiveFirstFax, setIsActiveFirstFax] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("type")
      ? params.get("type") === "active" || params.get("type") === "fax"
        ? true
        : false
      : true;
  });
  const [loadingMoreOptions, setLoadingMoreOptions] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentChatPage, setCurrentChatPage] = useState(1);
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || "";
  });
  const [isActiveFirst, setIsActiveFirst] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("type")
      ? params.get("type") === "active" || params.get("type") === "unsubscribed"
        ? true
        : false
      : true;
  });
  const [currentTab, setCurrentTab] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("type") || "active";
  });

  const mounted = useRef();
  const debounceTimeout = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const [currentFaxNumber, setCurrentFaxNumber] = useState();

  const openStatus = Boolean(anchorElStatus);
  const popupId = openStatus ? "simple-popover" : undefined;

  const [openedRow, setOpenedRow] = useState();
  const [currentActiveTab, setCurrentActiveTab] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("tab") || "sms&email";
  });

  const [selectedRecommendationStatus, setSelectedRecommendationStatus] =
    useState("");

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Incoming")
    ?.permissions.find(
      (itm) => itm.codename === "change_incoming"
    )?.is_authorized;

  useEffect(() => {
    if (currentActiveTab === "sms&email") {
      if (page === 1 && recentMessages) {
        if (recentMessages.length > 0) {
          setCurrentChatNumber({
            text: recentMessages?.[0]?.message,
            name:
              recentMessages?.[0]?.first_name +
              " " +
              recentMessages?.[0]?.last_name,
            phone: recentMessages?.[0]?.phone_number,
            id: recentMessages?.[0]?.id,
          });
        }
        setLoading(false);
      }
    }
  }, [page, recentMessages]);

  useEffect(() => {
    if (currentActiveTab === "sms&email") {
      if (currentChatNumber?.id) {
        dispatch(getRecentIncomingMessagesChats(currentChatNumber?.id, 1));
        dispatch(getReportByPatientId(currentChatNumber?.id, 1));
        dispatch(getRecommendationByPatientId(currentChatNumber?.id, 1));
        setReportsPage(1);
        setRecommendationsPage(1);
        setCurrentChatPage(1);
      }
    }
  }, [dispatch, currentChatNumber?.id]);

  useEffect(() => {
    if (currentActiveTab === "sms&email") {
      dispatch(getTwilioConfig());
      const query = new URLSearchParams(history.location.search);

      let locationFilter =
        query
          .getAll("location_address")
          ?.map((itm) => ({ value: itm, label: itm, category: "location" })) ||
        "";

      setSelectedFilters({
        location: locationFilter,
      });

      dispatch(
        getRecentIncomingMessages(
          0,
          searchQuery,
          currentTab === "unsubscribed" || currentTab === "active"
            ? false
            : true,
          1,
          currentTab,
          { location: locationFilter }
        )
      );
    }
  }, [dispatch]);

  const onShowDefaultResponses = () => {
    window.analytics.track("Messages-Edit-Default-Responses-Button-Click");
    setShowDefaultResponsesModal(true);
  };

  const clickCloseConversation = () => {
    setShowCloseConvDialog(!showCloseConvDialog);
  };

  const handleOngoingMessageButton = () => {
    history.push("/auth/radAdmin/messages/deliveries");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    window.analytics.track("Message-Incoming-Search-Click", { search: value });
    setSearchQuery(value);
    setLoading(true);
    setCurrentChatNumber("");
  };

  const updateURLParams = (paramsToUpdate) => {
    const params = new URLSearchParams(location.search);

    Object.keys(paramsToUpdate).forEach((key) => {
      if (paramsToUpdate[key] !== undefined && paramsToUpdate[key] !== "") {
        params.set(key, paramsToUpdate[key]);
      } else {
        params.delete(key);
      }
    });

    history.replace({ search: params.toString() });
  };

  useEffect(() => {
    if (currentActiveTab === "sms&email") {
      if (mounted.current) {
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }
        updateURLParams({ search: searchQuery });

        debounceTimeout.current = setTimeout(() => {
          dispatch(
            getRecentIncomingMessages(
              0,
              searchQuery,
              currentTab === "unsubscribed" || currentTab === "active"
                ? false
                : true,
              1,
              currentTab,
              selectedFilters
            )
          );
        }, 500);
      } else {
        mounted.current = true;
      }
    }

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("tab") !== "fax") {
      if (!params.get("type")) {
        updateURLParams({ tab: "sms&email", type: "active" });
      }
    }
    if (!params.get("tab")) {
      updateURLParams({ tab: "sms&email", type: "active" });
    }
  }, []);

  const LoadMoreReports = () => {
    window.analytics.track("Patient-Details-Load-More-Reports-Click");
    setReportsPage((prev) => prev + 1);
    dispatch(getReportByPatientId(currentChatNumber?.id, reportsPage + 1));
  };

  const LoadMoreRecommendations = () => {
    if (currentActiveTab === "sms&email") {
      window.analytics.track("Patient-Details-Load-More-Recommendations-Click");
      setRecommendationsPage((prev) => prev + 1);
      dispatch(
        getRecommendationByPatientId(
          currentChatNumber?.id,
          recommendationsPage + 1
        )
      );
    }
  };

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
    setOpenedRow();
  };

  const handleClickStatus = (event, id) => {
    setAnchorElStatus(event.currentTarget);
    setOpenedRow(id);
  };

  const closeSelected = (category, selectedItem) => {
    const filters = selectedFilters[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedFilters,
      [category]: filters.length > 0 ? filters : [],
    };
    setSelectedFilters(newFilters);

    let params = new URLSearchParams(history.location.search);

    params.delete("location_address");

    if (newFilters.location?.length > 0) {
      newFilters.location.forEach((itm) => {
        params.append("location_address", itm.value);
      });
    }

    history.replace({
      search: params.toString(),
    });
    setLoading(true);
    setPage(1);
    setCurrentChatNumber("");
    dispatch(
      getRecentIncomingMessages(
        0,
        searchQuery,
        currentTab === "unsubscribed" || currentTab === "active" ? false : true,
        1,
        currentTab,
        newFilters
      )
    );
  };

  const onLoadMoreChatMessages = () => {
    if (
      !isLoadingChatMessages &&
      currentChatNumber &&
      chatMessages?.length !== chatMessagesTotalItems
    ) {
      setCurrentChatPage((prev) => prev + 1);
      dispatch(
        getRecentIncomingMessagesChats(
          currentChatNumber?.id,
          currentChatPage + 1
        )
      );
    }
  };

  return (
    <Container>
      <CloseConversationDialog
        showCloseConvDialog={showCloseConvDialog}
        clickCloseConversation={clickCloseConversation}
        currentChatNumber={currentChatNumber}
        usersInfo={usersInfo}
        cb={() => {
          if (recentMessages.length > 0) {
            if (activeIndex === recentMessages?.length) {
              setActiveIndex((prev) => prev - 1);
              setCurrentChatNumber({
                text: recentMessages?.[activeIndex - 1]?.message,
                name:
                  recentMessages?.[activeIndex - 1]?.first_name +
                  " " +
                  recentMessages?.[activeIndex - 1]?.last_name,
                phone: recentMessages?.[activeIndex - 1]?.phone_number,
                id: recentMessages?.[activeIndex - 1]?.id,
              });
            } else {
              setCurrentChatNumber({
                text: recentMessages?.[activeIndex]?.message,
                name:
                  recentMessages?.[activeIndex]?.first_name +
                  " " +
                  recentMessages?.[activeIndex]?.last_name,
                phone: recentMessages?.[activeIndex]?.phone_number,
                id: recentMessages?.[activeIndex]?.id,
              });
            }
          } else {
            setCurrentChatNumber();
          }
        }}
        searchQuery={searchQuery}
        currentTab={currentTab}
        filters={selectedFilters}
        onLastPage={recentMessages?.length === recentMessagesTotalItems}
        page={page}
      />
      <IncomingMessagesContainer>
        <IncomingMessagesTitle>Incoming Messages</IncomingMessagesTitle>
        {
          <>
            <div style={{ width: "100%", display: "flex" }}>
              <SwitchContainer>
                <SwitchButton
                  active={currentActiveTab === "sms&email"}
                  onClick={() => {
                    window.analytics.track("Message-Incoming-Sms&Email-Click");
                    setCurrentActiveTab("sms&email");
                    updateURLParams({
                      tab: "sms&email",
                      type: "active",
                      fax_number: "",
                    });
                    setCurrentTab('active');
                    setCurrentChatNumber("");
                    setActiveIndex(0);
                    setIsActiveFirst(true);
                    setPage(1);
                    dispatch(
                      getRecentIncomingMessages(
                        0,
                        searchQuery,
                        false,
                        1,
                        "active",
                        selectedFilters
                      )
                    );
                  }}
                >
                  SMS & Email
                </SwitchButton>
                <SwitchButton
                  active={currentActiveTab === "fax"}
                  disabled={incommingFax?.isLoading}
                  onClick={() => {
                    window.analytics.track("Message-Incoming-fax-Click");
                    setLoading(true);
                    setCurrentActiveTab("fax");
                    setIsActiveFirst(true);
                    setCurrentFaxNumber("");
                    setSearchQuery('')
                    setPage(1);
                    setActiveIndex(0);
                    updateURLParams({ tab: "fax", type: "", search: "", location_address :'' });
                    setSelectedFilters({})
                  }}
                >
                  Fax
                </SwitchButton>
              </SwitchContainer>
            </div>
            {currentActiveTab === "sms&email" ? (
              <>
                <InfoWrapper>
                  <PhoneIcon />
                  <Info>Your Phone Number</Info>
                  <PhoneNumberLink href={`tel:${providerPhoneNumber}`}>
                    {providerPhoneNumber}
                  </PhoneNumberLink>
                </InfoWrapper>
                <SearchFilterRow>
                  <SearchBoxContainer>
                    <SearchBox
                      placeholder="Search by Name, Phone Number"
                      value={searchQuery}
                      onChange={handleInputChange}
                    />
                    <img
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "25px",
                      }}
                      src={searchIcon}
                    />
                  </SearchBoxContainer>

                  <FilterButton onClick={() => setShowFilters(true)}>
                    <Image src={filterOutline} alt={"settings icon"} />
                  </FilterButton>
                </SearchFilterRow>
                <ChipsContainer>
                  {Object.values(selectedFilters).map((item, index) => {
                    if (!item || !Array.isArray(item)) return null;
                    return item.map((i, idx) => (
                      <SelectedItemContainer key={index + idx}>
                        <SelectedItem>
                          {" "}
                          {i.category
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, function (char) {
                              return char.toUpperCase();
                            })}{" "}
                          :{" "}
                          {i.label.replace(/\b\w/g, function (char) {
                            return char.toUpperCase();
                          })}
                        </SelectedItem>
                        <CloseSign onClick={() => closeSelected(i.category, i)}>
                          <Image src={close} alt={"close sign"} />
                        </CloseSign>
                      </SelectedItemContainer>
                    ));
                  })}
                </ChipsContainer>
              </>
            ) : null}

            {currentActiveTab === "sms&email" ? (
              <div style={{ width: "100%", display: "flex" }}>
                <SwitchContainer>
                  <SwitchButton
                    active={currentTab === "active"}
                    disabled={isLoadingRecentMessages || isLoadingChatMessages}
                    onClick={() => {
                      if (
                        currentTab === "active" ||
                        isLoadingRecentMessages ||
                        isLoadingChatMessages
                      ) {
                        return;
                      }
                      window.analytics.track("Message-Incoming-Active-Click");
                      setLoading(true);
                      setCurrentTab("active");
                      setIsActiveFirst(true);
                      setPage(1);
                      setCurrentChatNumber("");
                      updateURLParams({ type: "active" });
                      setActiveIndex(0);
                      dispatch(
                        getRecentIncomingMessages(
                          0,
                          searchQuery,
                          false,
                          1,
                          "active",
                          selectedFilters
                        )
                      );
                    }}
                  >
                    Active
                  </SwitchButton>
                  <SwitchButton
                    active={currentTab === "closed"}
                    disabled={isLoadingRecentMessages || isLoadingChatMessages}
                    onClick={() => {
                      if (
                        currentTab === "closed" ||
                        isLoadingRecentMessages ||
                        isLoadingChatMessages
                      ) {
                        return;
                      }
                      window.analytics.track("Message-Incoming-Closed-Click");
                      setLoading(true);
                      setCurrentTab("closed");
                      setIsActiveFirst(false);
                      setCurrentChatNumber("");
                      setPage(1);
                      setActiveIndex(0);

                      updateURLParams({ type: "closed" });
                      dispatch(
                        getRecentIncomingMessages(
                          0,
                          searchQuery,
                          true,
                          1,
                          "closed",
                          selectedFilters
                        )
                      );
                    }}
                  >
                    Closed
                  </SwitchButton>
                  <SwitchButton
                    active={currentTab === "unsubscribed"}
                    disabled={isLoadingRecentMessages || isLoadingChatMessages}
                    onClick={() => {
                      if (
                        currentTab === "unsubscribed" ||
                        isLoadingRecentMessages ||
                        isLoadingChatMessages
                      ) {
                        return;
                      }
                      window.analytics.track(
                        "Message-Incoming-Unsubscribed-Click"
                      );
                      setLoading(true);
                      setCurrentTab("unsubscribed");
                      setIsActiveFirst(true);
                      setPage(1);
                      setActiveIndex(0);

                      setCurrentChatNumber("");
                      updateURLParams({ type: "unsubscribed" });
                      dispatch(
                        getRecentIncomingMessages(
                          0,
                          searchQuery,
                          false,
                          1,
                          "unsubscribed",
                          selectedFilters
                        )
                      );
                    }}
                  >
                    Unsubscribed
                  </SwitchButton>
                </SwitchContainer>
              </div>
            ) : null}

            {currentActiveTab === "sms&email" ? (
              <>
                {loading || (isLoadingRecentMessages && page === 1) ? (
                  <LoaderContainer>
                    <Loader2 />
                  </LoaderContainer>
                ) : recentMessages?.length == 0 ? (
                  <RecentMessagesContainer style={{ width: "225px" }}>
                    <MessagePreview
                      message={{
                        text: "You have no new messages",
                      }}
                      cb={() => {}}
                    ></MessagePreview>
                  </RecentMessagesContainer>
                ) : (
                  <RecentMessagesContainer>
                    <MessagesContainer>
                      {recentMessages?.map((itm, idx) => {
                        let patient_phone_number = itm?.phone_number || "";
                        let id = itm.id;
                        let message = itm.last_message || "";
                        return (
                          <MessagePreview
                            isActiveFirst={isActiveFirst}
                            cb={(obj) => {
                              setActiveIndex(
                                recentMessages.findIndex(
                                  (itm) => itm.id === obj.id
                                )
                              );
                              setCurrentChatNumber(obj);
                            }}
                            key={id}
                            message={{
                              text: message,
                              name: itm.first_name + " " + itm.last_name,
                              phone: patient_phone_number,
                              id: itm.id,
                            }}
                            lastMessageAt={itm.last_message_at}
                            currentChatNumber={currentChatNumber}
                          />
                        );
                      })}
                    </MessagesContainer>
                  </RecentMessagesContainer>
                )}
                {recentMessages?.length !== recentMessagesTotalItems &&
                  recentMessages?.length > 0 && (
                    <div style={{ width: "100%", marginBottom: "82px" }}>
                      <button
                        disabled={isLoadingRecentMessages || loadingMoreOptions}
                        onClick={() => {
                          window.analytics.track(
                            "Message-Incoming-Load-More-Click"
                          );
                          setLoadingMoreOptions(true);
                          dispatch(
                            getRecentIncomingMessages(
                              0,
                              searchQuery,
                              !isActiveFirst,
                              page + 1,
                              currentTab,
                              selectedFilters
                            )
                          ).then((res) => setLoadingMoreOptions(false));
                          setPage((prev) => prev + 1);
                        }}
                        style={{
                          border: "2px solid #00AEEF",
                          color: "#00AEEF",
                          width: "100%",
                          padding: "10px 88px 10px 88px",
                          background: "white",
                          borderRadius: "5px",
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {loadingMoreOptions ? (
                            <>LOADING...</>
                          ) : (
                            <>
                              LOAD MORE
                              <img src={loadMoreIcon} />
                            </>
                          )}
                        </span>
                      </button>
                    </div>
                  )}
              </>
            ) : (
              <Main
                currentFaxNumber={currentFaxNumber}
                setCurrentFaxNumber={setCurrentFaxNumber}
                setActiveIndex={setActiveIndex}
                isActiveFirstFax={isActiveFirstFax}
                providerData={provider}
              />
            )}
          </>
        }
      </IncomingMessagesContainer>
      {currentActiveTab === "sms&email" ? (
        <>
          <ChatContainer>
            {currentChatNumber && (
              <ChatNameContainer>
                <div style={{ marginBottom: "7px", display: "flex" }}>
                  <PhoneIcon />
                  <PhoneNumberLink href={`tel:${currentChatNumber?.phone}`}>
                    {currentChatNumber?.phone}
                  </PhoneNumberLink>
                </div>
                <div>
                  <ChatName
                    onClick={() => {
                      window.analytics.track(
                        `Incoming-Messages-Patient-Name-Click`
                      );
                      history.push(
                        `/auth/radAdmin/followUpList/patients/patientDetails/${currentChatNumber.id}`
                      );
                    }}
                  >
                    {currentChatNumber?.name}
                  </ChatName>
                </div>
              </ChatNameContainer>
            )}

            {chatMessages?.length === 0 || recentMessages?.length === 0 ? (
              <EmptyState
                title={"No Messages"}
                img={() => <EmptyStateMessageImg />}
                imageContainerStyles={{ width: "97px", height: "120px" }}
              >
                When you have new message,
                <br />
                you’ll see them here
              </EmptyState>
            ) : (
              <>
                <ChatMessagesContainer id="scrollableDiv">
                  {((isLoadingChatMessages && currentChatPage == 1) ||
                    (isLoadingRecentMessages && page == 1) ||
                    loading) && (
                    <ChatMessageLoaderContainer>
                      <Loader />
                    </ChatMessageLoaderContainer>
                  )}
                  {chatMessages?.length > 0 &&
                    recentMessages?.length > 0 &&
                    !loading && (
                      <InfiniteScroll
                        dataLength={chatMessages?.length ?? 0}
                        next={onLoadMoreChatMessages}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                        inverse={true}
                        hasMore={
                          chatMessages?.length !== chatMessagesTotalItems
                        }
                        loader={
                          <InfiniteScrollLoaderContainer>
                            <Spinner
                              animation="border"
                              role="status"
                              size="sm"
                            />
                          </InfiniteScrollLoaderContainer>
                        }
                        scrollableTarget="scrollableDiv"
                      >
                        {chatMessages?.map(
                          ({ id, message, direction, created }) => {
                            return (
                              <MessageContainer
                                key={id}
                                data-value={id}
                                inbound={direction === "inbound" ? true : false}
                              >
                                <Message
                                  message={{ text: message }}
                                  time={created}
                                  direction={direction}
                                />
                              </MessageContainer>
                            );
                          }
                        )}
                      </InfiniteScroll>
                    )}
                </ChatMessagesContainer>

                <MessageFormContainer>
                  <MessageForm
                    patientPhoneNumber={currentChatNumber?.phone}
                    twilioPhoneNumber={providerPhoneNumber}
                    onLastPage={chatMessages?.length === chatMessagesTotalItems}
                  />
                </MessageFormContainer>
              </>
            )}
          </ChatContainer>
          <AdditionalInfoContainer>
            <AdditionalInfo>
              {recentMessages?.length === 0 ? (
                <SeeOutgoing>See Outgoing</SeeOutgoing>
              ) : (
                currentChatNumber && (
                  <div>
                    <ButtonsContainer>
                      <EditDefaultResponsesButton
                        type="button"
                        onClick={onShowDefaultResponses}
                      >
                        <ConversationEditIcon style={{ marginRight: "5px" }} />
                        Edit Default Responses
                      </EditDefaultResponsesButton>
                    </ButtonsContainer>
                    <ButtonsContainer>
                      {isActiveFirst ? (
                        <CloseConversation
                          type="button"
                          onClick={() => {
                            if (
                              (permission && updatePermission) ||
                              !permission
                            ) {
                              clickCloseConversation();
                            } else {
                              dispatch(
                                showAlert(
                                  "danger",
                                  "Error",
                                  `You don't have permission to update conversation`
                                )
                              );
                            }
                          }}
                        >
                          <ConversationCloseIcon
                            style={{ marginRight: "10px" }}
                          />
                          Close Conversation
                        </CloseConversation>
                      ) : (
                        <OpenConversation
                          type="button"
                          onClick={() => {
                            if (
                              (permission && updatePermission) ||
                              !permission
                            ) {
                              dispatch(
                                toggleConversation(
                                  currentChatNumber?.id,
                                  { phone_closed: false },
                                  searchQuery,
                                  true,
                                  currentTab,
                                  selectedFilters,
                                  recentMessages?.length ===
                                    recentMessagesTotalItems,
                                  page
                                )
                              ).then((res) => {
                                if (res.updated) {
                                  // cb()
                                  if (recentMessages.length > 0) {
                                    if (
                                      activeIndex === recentMessages?.length
                                    ) {
                                      setActiveIndex((prev) => prev - 1);
                                      setCurrentChatNumber({
                                        text: recentMessages?.[activeIndex - 1]
                                          ?.message,
                                        name:
                                          recentMessages?.[activeIndex - 1]
                                            ?.first_name +
                                          " " +
                                          recentMessages?.[activeIndex - 1]
                                            ?.last_name,
                                        phone:
                                          recentMessages?.[activeIndex - 1]
                                            ?.phone_number,
                                        id: recentMessages?.[activeIndex - 1]
                                          ?.id,
                                      });
                                    } else {
                                      setCurrentChatNumber({
                                        text: recentMessages?.[activeIndex]
                                          ?.message,
                                        name:
                                          recentMessages?.[activeIndex]
                                            ?.first_name +
                                          " " +
                                          recentMessages?.[activeIndex]
                                            ?.last_name,
                                        phone:
                                          recentMessages?.[activeIndex]
                                            ?.phone_number,
                                        id: recentMessages?.[activeIndex]?.id,
                                      });
                                    }
                                  } else {
                                    setCurrentChatNumber();
                                  }
                                }
                              });
                            } else {
                              dispatch(
                                showAlert(
                                  "danger",
                                  "Error",
                                  `You don't have permission to update conversation`
                                )
                              );
                            }
                          }}
                        >
                          Reopen Conversation
                        </OpenConversation>
                      )}
                    </ButtonsContainer>

                    <CopyLinkButton
                      onClick={() => {
                        navigator.clipboard.writeText(
                          window.location.href +
                            `&search=${currentChatNumber?.phone}`
                        );
                        dispatch(
                          showAlert(
                            "success",
                            "Success",
                            "Link copied successfully!"
                          )
                        );
                      }}
                    >
                      Copy Link to Clipboard
                    </CopyLinkButton>
                  </div>
                )
              )}
            </AdditionalInfo>
            {recentMessages?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <EditDefaultResponsesButton
                  type="button"
                  onClick={onShowDefaultResponses}
                  style={{
                    width: "200px",
                    margin: 0,
                  }}
                >
                  <ConversationEditIcon style={{ marginRight: "5px" }} />
                  Edit Default Responses
                </EditDefaultResponsesButton>
                <OutgoingMessageButton onClick={handleOngoingMessageButton}>
                  Outgoing Messages
                </OutgoingMessageButton>
              </div>
            ) : (
              <ContainerBox></ContainerBox>
            )}
            {currentChatNumber && (
              <div>
                <ReportContainer>
                  <ReportsHeader>Reports</ReportsHeader>
                  {loading || isLoadingReports ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "250px",
                      }}
                    >
                      <Spinner size="sm" role="status" animation="border" />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          overflowY: "auto",
                          overflowX: "hidden",
                          height: "250px",
                          width: "620px",
                        }}
                      >
                        <Table
                          columns={[].concat(reportColumns.slice(0, 3), [
                            {
                              key: "id",
                              displayCell: (value, rowData) => (
                                <TemplateButton
                                  style={{
                                    height: "38px",
                                    borderRadius: "4px",
                                    borderWidth: "2px",
                                    padding: "9px 15px",
                                    width: "144px",
                                    gap: "8px",
                                  }}
                                  iconContainerStyles={{ padding: "0px" }}
                                  textStyles={{
                                    fontFamily: "Open Sans",
                                    lineHeight: "19px",
                                    whiteSpace: "nowrap",
                                  }}
                                  title="View Report"
                                  border={true}
                                  outlinedBr={"#00AEEF"}
                                  color="#00AEEF"
                                  icon={<InfoButtonIcon />}
                                  clickHandler={() => {
                                    window.analytics.track(
                                      "Patient-Details-Reports-View-Content-Click"
                                    );
                                    setSelectedData({
                                      visibility: true,
                                      data: {
                                        ...rowData,
                                        initialExamDate:
                                          rowData.initial_exam_date,
                                      },
                                    });
                                  }}
                                />
                              ),
                            },
                          ])}
                          rows={reports && reports}
                          isLoadingRow={isLoadingReports}
                        />
                      </div>
                      {reports.length > 0 && (
                        <LoadMoreContainerBox>
                          <LoadMoreContainer
                            disabled={
                              reports && totalReports === reports.length
                            }
                            onClick={
                              reports && totalReports !== reports.length
                                ? () => LoadMoreReports()
                                : null
                            }
                          >
                            {isLoadingReports ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              />
                            ) : (
                              "Load more reports"
                            )}
                          </LoadMoreContainer>
                        </LoadMoreContainerBox>
                      )}
                    </>
                  )}
                </ReportContainer>
                <ReportContainer>
                  <ReportsHeader>Recommendations</ReportsHeader>
                  {loading || isLoadingRecommendations ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "250px",
                      }}
                    >
                      <Spinner size="sm" role="status" animation="border" />
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          overflowY: "auto",
                          overflowX: "hidden",
                          height: "250px",
                          width: "620px",
                        }}
                      >
                        <Table
                          columns={[].concat(recommendationColumns, [
                            {
                              key: "status",
                              name: "Status",
                              displayCell: (status, row) => (
                                <>
                                  <StatusBadge
                                    onClick={(e) =>
                                      handleClickStatus(e, row.id)
                                    }
                                    status={status}
                                  />
                                </>
                              ),
                            },
                          ])}
                          rows={recommendations && recommendations}
                          isLoadingRow={isLoadingRecommendations}
                        />
                      </div>
                      {recommendations.length > 0 && (
                        <LoadMoreContainerBox>
                          <LoadMoreContainer
                            disabled={
                              recommendations &&
                              totalRecommendations === recommendations.length
                            }
                            onClick={
                              recommendations &&
                              totalRecommendations !== recommendations.length
                                ? () => LoadMoreRecommendations()
                                : null
                            }
                          >
                            {isLoadingRecommendations ? (
                              <Spinner
                                animation="border"
                                role="status"
                                size="sm"
                              />
                            ) : (
                              "Load more recommendations"
                            )}
                          </LoadMoreContainer>
                        </LoadMoreContainerBox>
                      )}
                      {openStatus && (
                        <Popover
                          id={popupId}
                          open={openStatus}
                          anchorEl={anchorElStatus}
                          onClose={handleCloseStatus}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          PaperProps={{
                            style: {
                              borderRadius: "10px",
                              border: "2px solid #DFE0EB",
                              width: "244px",
                              background: "#F7F8FF",
                              boxShadow: "none",
                              marginTop: "10px",
                            },
                          }}
                        >
                          <StatusesContainer ref={node}>
                            <ActionStatusList
                              selectedStatus={selectedRecommendationStatus}
                              id={openedRow}
                              handleSelectStatus={(data) => {
                                if (
                                  (permission && updatePermission) ||
                                  !permission
                                ) {
                                  setSelectedRecommendationStatus(data.status);
                                  dispatch(
                                    changeActionStatusActionPatientDetails(
                                      data.id,
                                      data.status
                                    )
                                  ).then((res) => {
                                    dispatch(
                                      getRecommendationByPatientId(
                                        currentChatNumber.id,
                                        1
                                      )
                                    );
                                    handleCloseStatus();
                                  });
                                } else {
                                  dispatch(
                                    showAlert(
                                      "danger",
                                      "Error",
                                      `You don't have permission to update recommendation status`
                                    )
                                  );
                                }
                              }}
                              fromMessage
                            />
                          </StatusesContainer>
                        </Popover>
                      )}
                    </>
                  )}
                </ReportContainer>
              </div>
            )}
          </AdditionalInfoContainer>
        </>
      ) : (
        <div style={{ width: "100%" }}>
          <FaxRecords
            currentFaxNumber={currentFaxNumber}
            providerData={provider}
          />
        </div>
      )}

      {selectedData?.visibility && (
        <ViewReportModal
          data={selectedData.data}
          showModal={selectedData.visibility}
          closeModal={() => setSelectedData({ visibility: false, data: {} })}
        />
      )}

      {showDefaultResponsesModal && (
        <DefaultResponsesModal
          showModal={showDefaultResponsesModal}
          closeModal={() => setShowDefaultResponsesModal(false)}
        />
      )}
      {showFilters && (
        <MessagesFilter
          showModal={showFilters}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          onClose={() => setShowFilters(false)}
          handleSubmit={(filters) => {
            setCurrentChatNumber("");
            setLoading(true);
            setPage(1);
            dispatch(
              getRecentIncomingMessages(
                0,
                searchQuery,
                currentTab === "unsubscribed" || currentTab === "active"
                  ? false
                  : true,
                1,
                currentTab,
                filters
              )
            );
          }}
        />
      )}
    </Container>
  );
};

export default Messages;

export const MessagesWithFeature = (props) => {
  return (
    <EnableFeature featureName={"view-messages"} {...props}>
      <Messages />
    </EnableFeature>
  );
};
