import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Box from "../../../components/_styles/Box";
import { HeaderContainer } from "../Cohorts/Create";
import { InfoButtonIcon } from "../FollowUpList/Icons";
import { PageTitle, Card } from "../../../styles";
import Table from "../../../components/Table/Table";
import SearchField from "../SharedComponents/SearchField";
import { ReactComponent as SearchIconCustom } from "../../../assets/searchIconGrey.svg";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { TemplateButton } from "../Messages/SmsCreationDialog";
import DoubleDataCell from "../PatientList/LastScanCell";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecoCohort,
  deleteReportCohort,
  getReportCohorts,
  showAlert,
} from "../../../actions/radminUploads";
import RemoveConfirmation from "./RemoveConfirmation";
import LoadMoreButton from "../../../components/LoadMoreButton";
import cioEvent from "../../../assets/cio_icon.svg";
import DescriptionChipGroups from "../RecommendationCohorts/DescriptionChipGroups";
import { parseQuery } from "../RecommendationCohorts/util";
import { CIOCampaignsIcon } from "../Messages/Icons";

const SearchFieldContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: right;
  height: 38px;
  margin-right: 17px;
`;

export const Image = styled.img`
  width: 20px;
  margin-right: 7px;
  color: #000000;
  fill: #000000;
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  campaignButton: {
    backgroundColor: "#00AEEF",
    textTransform: "none",
    width: "8rem",
    height: "38px",
    color: "#FFFFFF",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
  },
});

const DescriptionValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  text-align: initial;
  color: #000000;
  margin: 0px;
  display:flex;
  gap:15px;
  flex-wrap:wrap;
`;


const columns = [
  {
    key: "title",
    name: "Name",
    width: "20%",
    align: "center",
  },
  {
    key: "trigger_cio_event",
    name: "Messaging Campaign",
    width: "20%",
    displayCell: (cellValue, rowData) => (
        cellValue && <CIOCampaignsIcon />
    ),
    align: "left",
  },

  {
    key: "description",
    name: "Description",
    align: "left",
     displayCell: (cellValue, rowData) => {
        const { parents, operators } = parseQuery(cellValue);
      return ( 
        <DescriptionValue>
        <DescriptionChipGroups parents={parents} operators={operators} />
        </DescriptionValue>
      ) 
    }
  },
];



const CohortList = ({
  activeTab,
  titleForSearch,
  setTitleForSearch,
  page,
  setPage,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [page, setPage] = useState(1);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  // const [titleForSearch, setTitleForSearch] = useState("");
  const [isLoadingDeleteCohort, setIsLoadingDeleteCohort] = useState(false);
  const hasReportSearchEventsEnabled =  useSelector((state) => state.auth.user.provider.report_search_event);
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const createPermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "add_cohorts"
    )?.is_authorized;
  const deletePermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "delete_cohorts"
    )?.is_authorized;
  const updatePermission = rolePermissions
    .find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_cohorts"
    )?.is_authorized;


  useEffect(() => {
    if (activeTab === "reports") {
      const query = new URLSearchParams(history.location.search);
      let title = query.get("title");
      if (title) {
        dispatch(getReportCohorts(page, title));
      } else {
        dispatch(getReportCohorts(page, titleForSearch));
      }
    }
  }, []);

  useEffect(() => {
    if (activeTab === "reports") {
      const query = new URLSearchParams(history.location.search);
      let title = query.get("title");
      if (title) {
        setTitleForSearch(title);
        dispatch(getReportCohorts(null, title));
      }
    }
  }, []);

  const cohortReportList = useSelector(
    (state) => state.radAdmin?.cohortReports
  );

  const dialogHandler = (data) => {
    setRemoveDialogOpen(!removeDialogOpen);
    setSelectedId(data.id);
    window.analytics.track("Cohort-Report-Delete-Button-Click");
  };

  const deleteCohortHandler = () => {
    setIsLoadingDeleteCohort(true);
    dispatch(deleteReportCohort(selectedId)).then(() => {
      setIsLoadingDeleteCohort(false);
      setRemoveDialogOpen(false);
    });
  };

  const createCohortReportHandler = () => {
    history.push("/auth/radAdmin/messages/cohorts-report/create");
    window.analytics.track("Cohort-Report-Create-Button-Click");
  };

  const onLoadMore = () => {
    dispatch(getReportCohorts(page + 1, titleForSearch));
    setPage(page + 1);
  };

  const viewReportsHandler = (data) => {
    window.analytics.track("Cohort-Reports-View-Button-Click");
    history.push(`/auth/radAdmin/followUp/reports/cohorts/details/${data.id}`, {
      data: data,
    });
  };

  const finalColumns = hasReportSearchEventsEnabled? columns : columns.filter(itm=>itm.key !== 'trigger_cio_event')

  return (
    <Box>
      <RemoveConfirmation
        content="Are you sure you want to remove this cohort?"
        openDialog={removeDialogOpen}
        dialogHandler={dialogHandler}
        isLoading={isLoadingDeleteCohort}
        deleteHandler={deleteCohortHandler}
        heading={"Remove Cohort"}
        removeHeader={"Yes, Remove Cohort"}
      />
      {/* <HeaderContainer>
        <PageTitle>Cohorts</PageTitle>
        <SearchFieldContainer>
          <SearchField
            placeholder="Search Cohort Title"
            value={titleForSearch}
            onChangeHandle={(e) => {
              setTitleForSearch(e.target.value);
              history.push({
                search: `?title=${e.target.value}`,
              });
            }}
            onReset={() => {
              if (titleForSearch) {
                dispatch(getReportCohorts(1, ""));
                setPage(1);
                setTitleForSearch("");
                history.push({
                  search: `?title=`,
                });
              }
            }}
            onSearchEnter={() => {
              setPage(1);
              dispatch(getReportCohorts(null, titleForSearch));
            }}
            styles={{
              marginRight: 0,
              marginBottom: 0,
              width: "318px",
              position: "relative",
            }}
            placeholderStyles={{
              color: "#828282",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Open Sans",
              marginLeft: "32px",
            }}
            inputStyles={{
              background: "#FFFFFF",
              border: "1px solid #D2D3D7",
              textAlign: "left",
              paddingLeft: "20px",
              height: "38px",
            }}
            searchIconStyles={{
              marginLeft: "12px",
            }}
            clearIconStyle={{
              right: 20,
              marginLeft: 0,
            }}
            SearchIconCustom={<SearchIconCustom />}
          />
        </SearchFieldContainer>
        <div>
          <Button
            classes={{ label: classes.label, root: classes.campaignButton }}
            onClick={createCohortReportHandler}
          >
            Create Cohort
          </Button>
        </div>
      </HeaderContainer> */}
      {/* <CohortTab /> */}
      <Card>
        <Table
          rows={cohortReportList?.data && cohortReportList?.data}
          columns={ finalColumns.concat(
            {
              key: "1",
              name: "",
              displayCell: (cellValue, rowData) => (
                <TemplateButton
                  clickHandler={() => viewReportsHandler(rowData)}
                  border={true}
                  color={"#00AEEF"}
                  outlinedBr={"#00AEEF"}
                  title="View Reports"
                  width
                  icon={<InfoButtonIcon />}
                />
              ),
              // width: "20px",
            },
            {
              key: "2",
              name: "",
              displayCell: (cellValue, rowData) => (
                <DoubleDataCell
                  mainInfo={"edit"}
                  
              
 EditRecoHandler={() => {
                    if ((permission && updatePermission) || !permission) {
                      history.push(
                        "/auth/radAdmin/followUp/reports/cohorts/create",
                        { data: rowData }
                      );
                      window.analytics.track(
                        "Cohort-Report-Edit-Button-Click"
                      );
                    } else {
                      dispatch(
                        showAlert(
                          "danger",
                          "Error",
                          `You don't have permission to update reports cohort`
                        )
                      );
                    }
                  }}
                   RemoveRecoHandler={() => {
                                      if ((permission && deletePermission) || !permission) {
                                        dialogHandler(rowData);
                                      } else {
                                        dispatch(
                                          showAlert(
                                            "danger",
                                            "Error",
                                            `You don't have permission to delete reports cohort`
                                          )
                                        );
                                      }
                                    }}
                />
              ),
              // width: "20px",
            }
          )}
          isLoadingRows={
            cohortReportList?.isLoading &&
            (cohortReportList?.data.length === 0 ||
              cohortReportList?.data.length === 1)
          }
          getRowsAction={(pageNumber) =>
            getReportCohorts(pageNumber, titleForSearch)
          }
          noDataRender={() => (
            <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />
        {cohortReportList?.data && cohortReportList?.data.length !== 0 && (
          <LoadMoreButton
            title="Load more"
            isLoading={
              cohortReportList?.isLoading &&
              cohortReportList?.totalItems !== cohortReportList?.data.length
            }
            handleClick={onLoadMore}
            isDisabled={
              cohortReportList?.totalItems === cohortReportList?.data.length
            }
          />
        )}
      </Card>
    </Box>
  );
};

export default CohortList;
