import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../../../components/_styles/Flex";
import styled from "styled-components";
import { Popover } from "@material-ui/core";
import { ReactComponent as StatusIcon } from "../../../../assets/statusIcon.svg";
import { ReactComponent as DownArrow } from "../../../../assets/dropdown-outline-white.svg";
import { ReactComponent as FaxIcon } from "../../../../assets/fax-icon.svg";
import Table from "../../../../components/Table/Table";
import FaxTemplateModal from "../FaxCreationDialog";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { LoadMoreContainer } from "../../../../components/Dialog/EditFormBottom";
import {
  changeActionStatusActionPatientDetails,
  getFaxRecords,
  getRecommendationByPatientId,
  showAlert,
} from "../../../../actions/radminUploads";

import { TemplateButton } from "../SmsCreationDialog";
import ActionStatusList from "../../FollowUpList/ActionStatusList";
import { InfoIconOutline as InfoButtonIcon } from "../Icons";
import MessageContent from "../ShowMessageContent";
import { Link } from "react-router-dom";
import { memo } from "react";

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 2;
  height: 100%;
  // border-right: 1px solid #c2ccd9;
  overflow-y: auto;
`;

const NoDataContainer = styled(Flex)`
  justify-content: center;
  padding: 15px 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;

const IconContainerBox = styled.span`
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;

  svg {
    position: absolute;
    top: 0px;
    left: 0;
    transition: opacity 0.1s ease;
  }

  svg.dark {
    opacity: 1;
  }

  svg.light {
    opacity: 0;
  }

  .label {
    display: none;
    font-size: 9px;
    font-weight: 700;
    color: ${(props) => props.color};
  }
`;

const StatusesContainer = styled.div`
  cursor: pointer;
  border-radius: 8px;
`;

const AdditionalInfoContainer = styled.div`
  flex-grow: 1;
  max-width: 700px;
  padding-top:20px;
  border-left: 1px solid #c2ccd9;
`;

const AdditionalInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 4px;
  flex-direction: row;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #c2ccd9;
  color: #0c2945;
  font-weight: bold;
  gap: 9px;
  font-family: Roboto Condensed;
`;

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 600;
  font-family: Open Sans;
  white-space: nowrap;
  padding: 5px 16px 5px 16px;
  color: ${(props) => (props.status === "non_mature" ? "black" : "white")};
  background: ${(props) => {
    switch (props.status) {
      case "coming_due":
        return "#FFD57C";
      case "acknowledged":
        return "#00AEEF";
      case "overdue":
        return "#F73B3B";
      case "unsubscribed":
        return "#F73B3B";
      case "unresponsive":
        return "#808080";
      case "needs_prior_auth":
        return "#DD6ECD";
      case "needs_prescription":
        return "#FF5F65";
      case "non_compliant":
        return "#6f42c1";
      case "rx_received":
        return "#186EF0";
      case "navigator_closed":
        return "#A7A9AD";
      case "completed_elsewhere":
        return "#6F52ED";
      case "patient_deceased":
        return "#2E2F30";
      case "scheduled_not_completed":
        return "#FF7A00";
      case "not_clinically_necessary":
        return "#FFD57C";
      case "adherent":
        return "#64C67C";
      case "non_mature":
        return "2px solid #DFE0EB";
      case "expired":
        return "2px solid #6F42C1";
      case "Not available":
        return "none";
      default:
        return "#00AEEF";
    }
  }};
`;

const CopyLinkButton = styled.span`
  color: #00aeef;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-left: 15px;
`;

const ReportsHeader = styled.h4`
  color: #0c2945;
  font-size: 24px;
  font-weight: 700;
`;
const ReportContainer = styled.div`
  padding: 3px;
  min-height: 100%;
  & table td {
    padding-right: 10px;
    padding-left: 0px;
  }
  & table td:last-child {
    padding-right: 0px;
  }
  & table td:first-child {
    padding-left: 0px;
  }
  //   overflow-y: auto;
  // overflow-x: hidden;
`;
const LoadMoreContainerBox = styled.div`
  margin: 20px 0px;
`;

function formatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(" AM", "am")
    .replace(" PM", "pm")
    .replace(/(\d{1,2}):(\d{2})/, "$1:$2");
}

const reportColumns = [
  {
    key: "created",
    name: "Date",
    displayCell: (cellValue) => <div>{formatDate(cellValue)}</div>,
  },
  {
    key: "direction",
    name: "Direction"
  },
];

const recommendationColumns = [
  {
    key: "accessionNumber",
    name: "Accession Number",
  },
  {
    key: "modality",
    name: "Modality",
  },
  {
    key: "anatomy",
    name: "Anatomy",
  },
  {
    key: "mrn",
    name: "Patient MRN",
    displayCell: (cellValue, rowData) => <div>{rowData?.patient?.mrn}</div>,
  },
  {
    key: "rangeStart",
    name: "Min Due Date",
  },
  {
    key: "rangeEnd",
    name: "Max Due Date",
  },
];

export const StatusBadge = ({
  status,
  onClick,
  styles = {},
  dropDown = true,
}) => (
  <Badge onClick={onClick} status={status} style={styles}>
    {status?.replaceAll("_", " ")}
    {!dropDown &&
      (status === "adherent" ||
        status === "scheduled_not_completed" ||
        status === "rx_received") && (
        <StatusIcon style={{ marginLeft: "10px" }} />
      )}
    {dropDown && <DownArrow style={{ marginLeft: "10px" }} />}{" "}
  </Badge>
);

const FaxRecords = ({ currentFaxNumber, providerData }) => {
  const dispatch = useDispatch();
  const node = useRef();
  const containerRef = useRef(null);
  const recommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations?.data
  );

  const isLoadingRecommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations.isLoading
  );
  const totalRecommendations = useSelector(
    (state) => state.radAdmin.patientDetails.recommendations?.totalItems
  );

  const faxRecordsbyId = useSelector(
    (state) => state.radAdmin.faxes.faxRecords
  );

  const [recordsPage, setRecordsPage] = useState(1);
  const [recommendationsPage, setRecommendationsPage] = useState(1);
  const [selectedRecommendationStatus, setSelectedRecommendationStatus] =
    useState("");
  const [patientId, setPatientId] = useState(null);
  const [openFax, setOpenFax] = useState(false);
  const [openedRow, setOpenedRow] = useState();
  const [recipientFax, setRecipientFax] = useState();
  const [boundType, setBoundType] = useState();
  const [showMessageContent, setShowMessageContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [anchorElStatus, setAnchorElStatus] = React.useState(null);
  const [faxContent, setFaxContent] = useState("");
  const openStatus = Boolean(anchorElStatus);
  const popupId = openStatus ? "simple-popover" : undefined;

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Incoming")
    ?.permissions.find(
      (itm) => itm.codename === "change_incoming"
    )?.is_authorized;

  const handleCloseStatus = () => {
    setAnchorElStatus(null);
    setOpenedRow();
  };
  const handleClickStatus = (event, id) => {
    setAnchorElStatus(event.currentTarget);
    setOpenedRow(id);
  };

  const dialogHandler = () => {
    setShowMessageContent(!showMessageContent);
  };

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollHeight - scrollTop <= clientHeight + 50) {
      if (
        faxRecordsbyId?.data &&
        faxRecordsbyId?.totalItems !== faxRecordsbyId?.data?.length &&
        !faxRecordsbyId?.isLoading
      ) {
        loadMoreLogRecords();
      }
    }
  }, [faxRecordsbyId]);

  const loadMoreLogRecords = () => {
    setRecordsPage((prev) => prev + 1);
    dispatch(getFaxRecords(currentFaxNumber?.id, recordsPage + 1));
  };

  const LoadMoreRecommendations = () => {
    window.analytics.track("Incoming-Messages-Load-More-Recommendations-Click");
    setRecommendationsPage((prev) => prev + 1);
    dispatch(
      getRecommendationByPatientId(
        currentFaxNumber?.id,
        recommendationsPage + 1,
        "report__referring_physician"
      )
    );
  };

  const openFaxHandler = (state, id) => {
    window.analytics.track("Incoming-Messages-Send-Fax-Button-Click");
    setPatientId(id);
    setOpenFax(state);
  };

  useEffect(() => {
    const div = containerRef.current;
    if (!div) return;

    div.addEventListener("scroll", handleScroll);

    return () => {
      div.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (currentFaxNumber?.id) {
      setRecordsPage(1);
      setRecommendationsPage(1);
    }
  }, [currentFaxNumber?.id]);

  return (
    <div>
      {openFax && (
        <FaxTemplateModal
          openMessage={openFax}
          openMessageHandler={openFaxHandler}
          patientDetails={{
            referringPhysician: faxRecordsbyId?.data?.[0]?.referring_physician,
          }}
          cb={()=>{
            setRecordsPage(1);
            dispatch(getFaxRecords(currentFaxNumber?.id,  1));
          }}
            permissionGroup="Incoming"
            permissionName="incoming"
        />
      )}
      {currentFaxNumber ? (
        <AdditionalInfo>
          <span>{currentFaxNumber?.name}</span>
          <Link
            to={`/auth/radAdmin/referringDocs/${currentFaxNumber?.id}/patients`}
          >
            <CopyLinkButton
              onClick={() => {
                window.analytics.track("Patient-List-Navigation-Click");
              }}
            >
              View Quality Metrics
            </CopyLinkButton>
          </Link>

          <IconContainerBox color="#06739D">
            <FaxIcon
              className="dark"
              onClick={(e) => {
                e.stopPropagation();
                openFaxHandler(true, currentFaxNumber?.id);
              }}
              style={{
                color: "#00AEEF",
                cursor: "pointer",
              }}
            />
          </IconContainerBox>
        </AdditionalInfo>
      ) : null}

      <div style={{ display: "flex" }}>
        <StyledDiv>
          <div>
            <ReportContainer>
              <>
                <div
                  ref={containerRef}
                  style={{
                    overflowY: "auto",
                    overflowX: "hidden",
                    height: "900px",
                  }}
                >
                  {(!loading || !faxRecordsbyId?.isLoading) &&
                  faxRecordsbyId?.data?.length > 0 ? (
                    <Table
                      columns={[]?.concat(reportColumns.slice(0, 3), [
                        {
                          key: "id",
                          displayCell: (value, rowData) => (
                            <TemplateButton
                              style={{
                                height: "38px",
                                borderRadius: "4px",
                                borderWidth: "2px",
                                marginRight: "0px !important",
                                width: "144px",
                              }}
                              iconContainerStyles={{ padding: "0px" }}
                              textStyles={{
                                fontFamily: "Open Sans",
                                lineHeight: "19px",
                                whiteSpace: "nowrap",
                              }}
                              title="View Content"
                              border={true}
                              outlinedBr={"#00AEEF"}
                              color="#00AEEF"
                              icon={<InfoButtonIcon />}
                              clickHandler={() => {
                                window.analytics.track(
                                  "Patient-Details-Reports-View-Content-Click"
                                );
                                // setSelectedData({
                                //   visibility: true,
                                //   data: {
                                //     ...rowData,
                                //     initialExamDate:
                                //       rowData.initial_exam_date,
                                //   },
                                // });
                                window.analytics.track(
                                  "Message-Deliveries-Fax-View-Content-Click"
                                );
                                setFaxContent(rowData?.attachment);
                                setRecipientFax(
                                  rowData?.referring_physician?.fax
                                );
                                setBoundType(rowData?.direction);
                                setShowMessageContent(!showMessageContent);
                              }}
                            />
                          ),
                        },
                      ])}
                      rows={
                        (currentFaxNumber &&
                          faxRecordsbyId?.data &&
                          faxRecordsbyId?.data) ||
                        []
                      }
                      isLoadingRow={faxRecordsbyId?.isLoading}
                    />
                  ) : (
                    !loading && !faxRecordsbyId?.isLoading && faxRecordsbyId?.data?.length === 0 && (
                      <NoDataContainer>no data</NoDataContainer>
                    )
                  )}

                  {loading || faxRecordsbyId?.isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "250px",
                      }}
                    >
                      <Spinner size="sm" role="status" animation="border" />
                    </div>
                  ) : null}
                </div>
              </>
            </ReportContainer>
          </div>
        </StyledDiv>
        <AdditionalInfoContainer>
         { currentFaxNumber &&
          <div>
            <ReportContainer>
              <ReportsHeader>Recommendations</ReportsHeader>
              {
                ((loading || isLoadingRecommendations) && recommendationsPage === 1) ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "250px",
                                      }}
                                    >
                                      <Spinner size="sm" role="status" animation="border" />
                                    </div>
                                  ) : (
                                    <>
                                    <div
                                      style={{
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        width: "670px",
                                      }}
                                    >
                                      
                                        <Table
                                          columns={[].concat(recommendationColumns, [
                                            {
                                              key: "status",
                                              name: "Status",
                                              displayCell: (status, row) => (
                                                <>
                                                  <StatusBadge
                                                    onClick={(e) => handleClickStatus(e, row.id)}
                                                    status={status}
                                                  />
                                                </>
                                              ),
                                            },
                                          ])}
                                          rows={(currentFaxNumber && recommendations) || []}
                                          isLoadingRow={isLoadingRecommendations}
                                        />
                                      
                    
                                   
                    
                                      
                                    </div>
                                    {currentFaxNumber && recommendations.length > 0 && (
                                      <LoadMoreContainerBox>
                                        <LoadMoreContainer
                                          disabled={
                                            recommendations &&
                                            totalRecommendations === recommendations.length
                                          }
                                          onClick={
                                            recommendations &&
                                            totalRecommendations !== recommendations.length
                                              ? () => LoadMoreRecommendations()
                                              : null
                                          }
                                        >
                                          {isLoadingRecommendations ? (
                                            <Spinner animation="border" role="status" size="sm" />
                                          ) : (
                                            "Load more recommendations"
                                          )}
                                        </LoadMoreContainer>
                                      </LoadMoreContainerBox>
                                    )}
                                    {openStatus && (
                                      <Popover
                                        id={popupId}
                                        open={openStatus}
                                        anchorEl={anchorElStatus}
                                        onClose={handleCloseStatus}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "center",
                                        }}
                                        transformOrigin={{
                                          vertical: "top",
                                          horizontal: "center",
                                        }}
                                        PaperProps={{
                                          style: {
                                            borderRadius: "10px",
                                            border: "2px solid #DFE0EB",
                                            width: "244px",
                                            background: "#F7F8FF",
                                            boxShadow: "none",
                                            marginTop: "10px",
                                          },
                                        }}
                                      >
                                        <StatusesContainer ref={node}>
                                          <ActionStatusList
                                            selectedStatus={selectedRecommendationStatus}
                                            id={openedRow}
                                            handleSelectStatus={(data) => {
                                              if ((permission && updatePermission) || !permission) {
                                                setSelectedRecommendationStatus(data.status);
                                                dispatch(
                                                  changeActionStatusActionPatientDetails(
                                                    data.id,
                                                    data.status
                                                  )
                                                ).then((res) => {
                                                  dispatch(
                                                    getRecommendationByPatientId(
                                                      currentFaxNumber.id,
                                                      1,
                                                      "report__referring_physician"
                                                    )
                                                  );
                                                  handleCloseStatus();
                                                });
                                              } else {
                                                dispatch(
                                                  showAlert(
                                                    "danger",
                                                    "Error",
                                                    `You don't have permission to update recommendation status`
                                                  )
                                                );
                                              }
                                            }}
                                            fromMessage
                                          />
                                        </StatusesContainer>
                                      </Popover>
                                    )}
                                  </>
                                  )
              }
          
            </ReportContainer>
          </div>
         }
        </AdditionalInfoContainer>
      </div>

      <MessageContent
        messageContent={faxContent}
        show={showMessageContent}
        dialogHandler={dialogHandler}
        customHeader="Fax Content"
        isEmailType={false}
        recipientFax={ boundType ==='inbound'? 'Me' : recipientFax || "-"}
        sourceFax={ boundType ==='inbound'? recipientFax: ('Me' || "-")}
        isPdf={true}
        styles={{ width: "60rem" }}
      />
    </div>
  );
};

export default memo(FaxRecords);
