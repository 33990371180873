import React, { useEffect, useRef, useState } from "react";
import { Card, PageTitle } from "../../../styles";
import Table from "../../../components/Table/Table";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-followup.svg";
import { ReactComponent as ExpandMoreIcon } from "../../../assets/expandMore.svg";
import { ReactComponent as SearchIconCustom } from "../../../assets/searchIconGrey.svg";
import {
  createCohortExport,
  getReportCohortDataWithId,
  getPatientsInCohort,
  getReportCohortWithId,
  showAlert,
  updateCohortReports,
} from "../../../actions/radminUploads";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { ReactComponent as FollowupRecommendedIcon } from "../../../assets/follow-up-recommended-icon.svg";
import { RecommendationTooltip } from "./../SearchReportText/RecommendationTooltip";
import ViewReportModal from "../Messages/ViewReportModal";
import { HeaderContainer } from "../Cohorts/ViewPatients";
import SearchField from "../SharedComponents/SearchField";
import { Button, makeStyles } from "@material-ui/core";
import Box from "../../../components/_styles/Box";
import DoubleDataCell from "../PatientList/LastScanCell";
import ExportCohortModal from "../Cohorts/ExportCohortModal";
import { BackNavigateIcon } from "../RecommendationCohorts/Icons";
import { parseQuery } from "../RecommendationCohorts/util";
import DescriptionChipGroups from "../RecommendationCohorts/DescriptionChipGroups";
import moment from "moment";

const StyledEmptyContainer = styled.div`
  display: flex;
  height: 300px;
  justify-content: center;
  align-items: center;
`;
const StyledLink = styled(Link)`
  color: #00aeef;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 26px;
  margin-bottom: 13px;
  gap: 5px;
  margin-top: 40px;
`;

const CohortDescriptionContainer = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isClicked ? "" : "3")};
  font-size: 13px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 21px;
  margin-left: 25px;
  font-weight: 100px;
`;

const Description = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  font-weight: 700;
  text-align: initial;
  color: #000000;
  margin: 0px;
`;

const DescriptionValue = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-size: 14px;
  text-align: initial;
  color: #000000;
  margin: 0px;
  display: flex;
  gap: 15px;
  fex-wrap: wrap;
`;
const SearchFieldContainer = styled.div`
  display: flex;
  flex: auto;
  justify-content: right;
  height: 38px;
  margin-right: 17px;
`;

const HeadingLabel = styled.h1`
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  color: #0c2945;
  margin-bottom: 0px;
`;

const RecalculateContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;
const RecalculateCohortLabel = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #00aeef;
  cursor: pointer;
  text-decoration: underline;
`;
const LoadingLabel = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #00aeef;
`;
const LastUpdatedLabel = styled.span`
  font-size: 14px;
  font-family: Roboto Condensed;
`;

const ReportsCount = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #0c2945;
  justify-content: center;
`;

const useStyles = makeStyles({
  label: {
    padding: "5px 0px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  campaignButton: {
    backgroundColor: "#00AEEF",
    textTransform: "none",
    width: "9rem",
    height: "38px",
    color: "#FFFFFF",
    border: "2px solid #00AEEF",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#00AEEF",
    },
  },
});

const ReportComponent = ({
  reportText,
  row,
  rows,
  idx,
  hasMoreRecords,
  onLoadMore,
  isLoading,
}) => {
  const [showFullReport, setShowFullReport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    report_text: row?.report_text,
    accession_number: row?.accession_number,
    initial_exam_date: row?.initial_exam_date,
    modality: row?.reco_modality,
    patient: { mrn: row?.patient_mrn, id: row?.patient_id },
    report_id: row?.id,
  });

  const [index, setIndex] = useState(idx);

  const toggleReportVisibility = () => {
    window.analytics.track(
      `Search-Report-Visibility-${
        !showFullReport ? "Expanded" : "Collapsed"
      }-Click`
    );
    setShowFullReport(!showFullReport);
    setShowModal(true);
  };

  useEffect(() => {
    setData({
      report_text: rows[index]?.report_text,
      accession_number: rows[index]?.accession_number,
      initial_exam_date: rows[index]?.initial_exam_date,
      modality: rows[index]?.reco_modality,
      patient: { mrn: rows[index]?.patient_mrn, id: rows[index]?.patient_id },
      report_id: rows[index]?.id,
    });
  }, [index, rows]);

  return (
    <span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "21px",
        }}
      >
        <span style={{ maxWidth: "450px" }}>
          {reportText.length >= 100
            ? reportText.slice(0, 100) + "..."
            : reportText}
        </span>
        <ExpandMoreIcon
          style={{ width: 18, height: 18, cursor: "pointer" }}
          onClick={toggleReportVisibility}
        />
        {showModal && (
          <ViewReportModal
            data={data}
            showModal={showModal}
            closeModal={() => {
              setShowModal(false);
              setIndex(idx);
              setData({
                report_text: row?.report_text,
                accession_number: row?.accession_number,
                initial_exam_date: row?.initial_exam_date,
                modality: row?.reco_modality,
                patient: { mrn: row?.patient?.mrn, id: row?.patient?.id },
                report_id: row?.id,
              });
            }}
            handlePrev={() => {
              if (index > 0) {
                setIndex((prev) => prev - 1);
              }
            }}
            handleNext={() => {
              if (index === rows.length - 1 && hasMoreRecords) {
                onLoadMore();
                setIndex((prev) => prev + 1);
              } else if (index < rows.length - 1) {
                setIndex((prev) => prev + 1);
              }
            }}
            isPaginated={true}
            disabledPrev={index === 0}
            disabledNext={index === rows.length - 1 && !hasMoreRecords}
            isLoading={isLoading}
            showCreateRecommendations={false}
          />
        )}
      </div>
    </span>
  );
};

const ViewReports = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const cohortReportListWithId = useSelector(
    (state) => state.radAdmin.cohotReportwithId
  );
  const cohortWithId = useSelector(
    (state) => state.radAdmin.reportCohortDataWithId.data
  );

  const [page, setPage] = useState(1);
  const [titleForSearch, setTitleForSearch] = useState("");
  const [selectedCohortId, setSelectedCohortId] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [showCohortModal, setShowCohortModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const [lastUpdated, setLastUpdated] = useState(() =>
    moment(cohortWithId?.last_updated).fromNow()
  );

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Cohorts")
    ?.permissions.find(
      (itm) => itm.codename === "change_cohorts"
    )?.is_authorized;
  const exportPermission = rolePermissions
    ?.find((itm) => itm.name === "Export")
    ?.permissions.find((itm) => itm.codename === "add_export")?.is_authorized;

  useEffect(() => {
    if (
      (cohortWithId?.status === "in_progress" ||
        cohortWithId?.status === "created") &&
      !loading
    ) {
      setLoading(true);
      setStatus("in_progress");
      startPolling();
    }
  }, [cohortWithId?.id]);

  const handleRecalculateReports = async () => {
    if (!loading) {
      if ((permission && updatePermission) || !permission) {
        setLoading(true);
        try {
          const response = await dispatch(updateCohortReports(id));
          if (response?.data?.status === "in_progress") {
            setStatus("in_progress");
            startPolling();
          } else if (response?.data?.status === "errored") {
            setStatus("errored");
            setLoading(false);
          }
        } catch (error) {
          setStatus("errored");
          setLoading(false);
        }
      } else {
        dispatch(
          showAlert(
            "danger",
            "Error",
            `You don't have permission to update cohort`
          )
        );
      }
    }
  };
  const intervalRef = useRef(null);

  const startPolling = () => {
    intervalRef.current = setInterval(checkStatus, 10000);
  };

  const stopPolling = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const checkStatus = async () => {
    try {
      const query = new URLSearchParams(history.location.search);
      let title = query.get("accession_number");

      const response = await dispatch(getReportCohortDataWithId(id));
      if (response && response.payload?.status === "completed") {
        stopPolling();
        setStatus("completed");
        setLoading(false);
        setPage(1);
        dispatch(
          getReportCohortWithId(
            1,
            props.location.state.data?.id,
            title ? title : titleForSearch
          )
        );
      } else if (response && response.payload?.status === "errored") {
        stopPolling();
        setStatus("errored");
        setLoading(false);
      }
    } catch (error) {
      stopPolling();
      setStatus("errored");
      setLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLastUpdated(moment(cohortWithId?.last_updated).fromNow());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [cohortWithId?.last_updated]);

  const onLoadMore = () => {
    if (props.location.state?.data) {
      const { data } = props.location.state;
      dispatch(getReportCohortWithId(page + 1, data?.id, titleForSearch));
    }
    setPage(page + 1);
  };

  const SearchReportsColumns = [
    { key: "accession_number", name: "Accession Number", align: "left" },
    {
      key: "patient_mrn",
      name: "Patient MRN",
      displayCell: (patient, row) => (
        <StyledLink
          to={`/auth/radAdmin/followUpList/patients/patientDetails/${row?.patient?.id}`}
          target="_blank"
          onClick={(e) => {
            e.stopPropagation();
            window.analytics.track(`Reports-Search-List-Patient-MRN-Click`);
          }}
        >
          {row?.patient?.mrn ?? "-"}
        </StyledLink>
      ),
      align: "left",
    },
    { key: "initial_exam_date", name: "Exam Date", align: "left" },
    {
      key: "follow_up_recommended",
      name: "Follow-up Recommended",
      align: "left",
      displayCell: (cellValue, rowData) =>
        cellValue ? (
          <RecommendationTooltip
            recommendations={rowData?.recommendations ?? []}
          >
            <FollowupRecommendedIcon style={{ cursor: "pointer" }} />
          </RecommendationTooltip>
        ) : (
          "-"
        ),
    },
    {
      key: "report_text",
      name: "Report Text",
      displayCell: (report, row, idx, rows) => {
        return (
          <ReportComponent
            reportText={report}
            row={row}
            rows={rows}
            idx={idx}
            hasMoreRecords={
              cohortReportListWithId?.totalItems !==
              cohortReportListWithId?.data?.length
            }
            onLoadMore={onLoadMore}
            isLoading={cohortReportListWithId?.isLoading}
          />
        );
      },
      align: "left",
    },
  ];

  const checkboxHandler = (data) => {
    if (selectedIds.includes(data.id)) {
      const filteredItems = selectedIds.filter((item) => {
        return item !== data.id;
      });
      setSelectedIds([...filteredItems]);
    }
    if (!selectedIds.includes(data.id)) {
      selectedIds.push(data.id);
      setSelectedIds([...selectedIds]);
    }
  };

  const { parents, operators } = parseQuery(selectedCohortId?.description);

  const createCohortHandler = () => {
    if ((permission && exportPermission) || !permission) {
      dispatch(createCohortExport(id, selectedIds)).then((res) => {
        if (res) setShowCohortModal(true);
      });
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to export cohort`
        )
      );
    }
  };

  const getOperator = (filter) => {
    let readAbleOperator = "";
    if (filter.operator === "__gt") {
      readAbleOperator = "Is a timestamp after";
    } else if (filter.operator === "__lt") {
      readAbleOperator = "Is a timestamp before";
    } else if (filter.operator === "__lte") {
      readAbleOperator = "Is a timestamp less than";
    } else if (filter.operator === "__gte") {
      readAbleOperator = "Is a timestamp greater than";
    } else if (filter.operator === "__icontains" && filter.exclude === 0) {
      readAbleOperator = "Contains";
    } else if (filter.operator === "__icontains" && filter.exclude === 1) {
      readAbleOperator = "Does not contain";
    } else if (filter.exclude) {
      readAbleOperator = "Is not equal to";
    } else {
      readAbleOperator = "Is equal to";
    }
    return readAbleOperator;
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let title = query.get("accession_number");

    if (props.location.state?.data) {
      const { data } = props.location.state;
      setSelectedCohortId(data);
      if (data?.id) {
        dispatch(
          getReportCohortWithId(page, data?.id, title ? title : titleForSearch)
        );
        dispatch(getReportCohortDataWithId(data?.id));
      }
    }
  }, []);

  return (
    <Box p={30}>
      <HeaderContainer>
        {/* <PageTitle>{selectedCohortId?.description}</PageTitle> */}
        <SearchFieldContainer>
          <SearchField
            placeholder="Search by Accession Number"
            value={titleForSearch}
            onChangeHandle={(e) => {
              setTitleForSearch(e.target.value);
              history.push({
                search: `?accession_number=${e.target.value}`,
              });
            }}
            onReset={() => {
              if (titleForSearch) {
                dispatch(getReportCohortWithId(1, selectedCohortId?.id, ""));
                setPage(1);
                setTitleForSearch("");
                history.push({
                  search: `?accession_number=`,
                });
              }
            }}
            onSearchEnter={() => {
              setPage(1);
              if (selectedCohortId?.id) {
                dispatch(
                  getReportCohortWithId(1, selectedCohortId?.id, titleForSearch)
                );
              }
            }}
            styles={{
              marginRight: 0,
              marginBottom: 0,
              width: "318px",
              position: "relative",
            }}
            placeholderStyles={{
              color: "#828282",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Open Sans",
              marginLeft: "32px",
            }}
            inputStyles={{
              background: "#FFFFFF",
              border: "1px solid #D2D3D7",
              textAlign: "left",
              paddingLeft: "20px",
              height: "38px",
            }}
            searchIconStyles={{
              marginLeft: "12px",
            }}
            clearIconStyle={{
              right: 20,
              marginLeft: 0,
            }}
            SearchIconCustom={<SearchIconCustom />}
          />
        </SearchFieldContainer>

        <div>
          <Button
            classes={{ label: classes.label, root: classes.campaignButton }}
            onClick={createCohortHandler}
          >
            {selectedIds?.length > 0
              ? "Export Selected Reports"
              : "Export Reports"}
          </Button>
        </div>
        <ExportCohortModal
          showModal={showCohortModal}
          closeModal={() => setShowCohortModal(false)}
        />
      </HeaderContainer>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        {" "}
        <span
          onClick={() =>
            history.push(`/auth/radAdmin/followUp/cohorts?tab=reports`)
          }
          style={{ cursor: "pointer" }}
        >
          <BackNavigateIcon />
        </span>
        <HeadingLabel>Reports:</HeadingLabel>
        <ReportsCount>{cohortWithId?.total_reports || 0}</ReportsCount>
        <RecalculateContainer>
          {!loading && (
            <RecalculateCohortLabel onClick={handleRecalculateReports}>
              Recalculate Reports
            </RecalculateCohortLabel>
          )}
          {loading && <LoadingLabel>Refreshing...</LoadingLabel>}
          {lastUpdated && !loading && (
            <LastUpdatedLabel> Last Updated : {lastUpdated}</LastUpdatedLabel>
          )}
        </RecalculateContainer>
      </div>
      <div>
        <HeadingWrapper>
            <Description>Cohort Description:</Description>
            <DescriptionValue>
                   <DescriptionChipGroups parents={parents} operators={operators} />
            </DescriptionValue>
        </HeadingWrapper>
      </div>
      <Card>
        <>
          {cohortReportListWithId?.totalItems === null &&
          !cohortReportListWithId?.isLoading ? (
            <StyledEmptyContainer>
              <span>Type search or select filters to fetch results</span>
            </StyledEmptyContainer>
          ) : (
            <>
              <Table
                rows={cohortReportListWithId?.data}
                initialPageNumber={cohortReportListWithId?.pageNumber}
                columns={[
                  {
                    key: "",
                    name: "",
                    displayCell: (cellValue, rowData) => (
                      <DoubleDataCell
                        mainInfo={"checkbox"}
                        checkboxHandler={() => checkboxHandler(rowData)}
                        // checked={selectedIds?.includes(rowData?.id)}
                      />
                    ),
                  },
                ].concat(SearchReportsColumns)}
                checkboxHandler={checkboxHandler}
                totalItems={cohortReportListWithId?.totalItems}
                isLoadingRows={cohortReportListWithId?.isLoading && page < 2}
                noDataRender={() => (
                  <EmptyState
                    title={"No Results"}
                    img={() => <EmptyStateImg />}
                    imageContainerStyles={{ width: "73px", height: "81px" }}
                  >
                    Sorry, there are no results
                  </EmptyState>
                )}
                shouldGetRowActions={false}
              />
              {cohortReportListWithId?.data &&
                cohortReportListWithId?.data.length !== 0 && (
                  <LoadMoreButton
                    title="Load more reports"
                    handleClick={onLoadMore}
                    isDisabled={
                      cohortReportListWithId?.totalItems ===
                      cohortReportListWithId?.data?.length
                    }
                    isLoading={cohortReportListWithId?.isLoading}
                    style={{
                      backgroundColor: "#0c2945",
                      fontWeight: 600,
                    }}
                  />
                )}
            </>
          )}
        </>
      </Card>
    </Box>
  );
};

export default ViewReports;
