import React, { useState, useEffect, Fragment, forwardRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { isNil } from 'lodash';
import Flex from '../_styles/Flex';
import DoubleDataCell from '../../pages/RadAdmin/PatientList/LastScanCell';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { ReactComponent as IconChevronLeft } from '../../assets/chevron_left.svg';
import { ReactComponent as IconChevronRight } from '../../assets/chevron_right.svg';

export const AvatarContainer = styled(Flex)`
  margin-right: 25px;
`;
export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
`;

export const UserEmptyAvatar = styled(Flex)`
  width: 40px;
  height: 40px;
  background: #f1f1f1;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #666b86;
`;

export const UserDataBlock = ({ name, avatar, additionalInfo }) => {
  const splitName = name.split(' ');
  const initials = (
    (splitName[0] ? splitName[0].charAt(0) : '') +
    (splitName[1] ? splitName[1].charAt(0) : '')
  ).toUpperCase();
  return (
    <Flex>
      <AvatarContainer>
        {avatar ? (
          <UserAvatar src={avatar} />
        ) : (
          <UserEmptyAvatar>{initials}</UserEmptyAvatar>
        )}
      </AvatarContainer>
      <DoubleDataCell mainInfo={name} additionalInfo={additionalInfo} />
    </Flex>
  );
};

const TableContainer = styled.table`
  width: 100%;
  overflow: auto;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const TableRow = styled.tr`
  position:relative;
  background: ${props=>props.isDisabled? "#E6E9F1"  : props.color? props.color :"initial"}
  &:hover {
    background: ${props=>props.isDisabled? "#E6E9F1" : props.color? props.color :"#f9fafc"}
    transition: all 0.3s ease;

    & td:first-child::before {
    content: "${props=>props.content? props.content : ''}"; 
    position: absolute;
    left: 30px; 
    top: 3px;
    background: ${props=>props.content? props.labelBackgroundColor:''};
    color: ${props=>props.content?props.labelColor:''};
    border-radius: 4px;
    z-index: 1;
    width:276px;
    text-align:center;
  }

  }
  
`;

const TableCell = styled.td`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: ${(props) => props.align};
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #252733;
  padding-top: 26px;
  padding-bottom: 26px;
  &:last-child {
    padding-right: 32px;
  }
  &:first-child {
    padding-left:${props => props.paddingLeft? props.paddingLeft: '32px' };
  }
`;

const TableHeaderCell = styled(TableCell)`
  width: ${(props) => props.width};
  font-weight: 600;
  font-size: 12px;
  text-align:${(props) => props.align};
  letter-spacing: 0.2px;
  color: #9fa2b4;
  white-space:nowrap;
  &:first-child {
    padding-left:${props => props.paddingLeft? props.paddingLeft: '32px' };
  }
`;

const ArrowUpwardIconStyled = styled(ArrowUpwardIcon)`
cursor:pointer;
color: ${({active})=>active? '#00AEEF' : '#c3c3c3' };
margin-left:5px;
`

const ArrowDownwardIconStyled = styled(ArrowDownwardIcon)`
cursor:pointer;
color: ${({active})=>active? '#00AEEF' : '#c3c3c3' };
`


const SpinnerContainer = styled(Flex)`
  justify-content: center;
  margin: 30px 0;
  align-items: center;
`;

const NoDataContainer = styled(Flex)`
  justify-content: center;
  padding: 15px 0;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
`;

const ChevronContainer = styled.div`
display:flex;
justify-content:flex-end;
// margin-Top:20px;
align-items:center;
`

const ChevronContainerLeft = styled.div`
cursor: ${props=>props.isAtStart? 'not-allowed' : 'pointer'};
border:1px solid #DFE0EB;
position: fixed;
left:30px;
top: ${props=>props.top};
z-index:999;
background:#F9FAFCCC;
width:70px; 
height:${props=>props.rows.length<3?"50px":'290px'};
border-radius:0px 4px 4px 0px;
box-shadow: 0px 4px 10px 3px #00000014;
display:flex;
justify-content:center;
align-items:center;
`

const ChevronContainerRight = styled.div`
cursor: ${props=>props.isAtStart? "pointer" : props.isAtEnd?'not-allowed': 'pointer'};
border:1px solid #DFE0EB;
position: fixed;
right:30px;
top: ${props=>props.top};
z-index:999;
background:#F9FAFCCC;
width:70px;
height:${props=>props.rows.length<3?"50px":'290px'};
border-radius:4px 0px 0px 4px;
box-shadow: 0px 4px 10px 3px #00000014;
display:flex;
justify-content:center;
align-items:center;
`


const Table = forwardRef(
  (
    {
      rows,
      columns,
      isActiveFirst,
      initialPageNumber,
      totalItems,
      isLoadingRows,
      getRowsAction,
      renderButtonsColumn,
      resetDataAction,
      noDataRender,
      cb=()=>{},
      setActiveSortColumn = ()=>{},
      activeSortColumn=null,
      selectAllCb,
      allSelected,
      selectedIds,
      checkBoxAtStart=true,
      disabledRow="",
      fromRecoValidation=false,
      showOnlyLabel=false,
      shouldGetRowActions=true,
      handleScrollLeft=()=>{},
      handleScrollRight= ()=>{},
      isAtStart,
      isAtEnd,
      showHorizontalScrollChevrons = false,
      showCustomColor=false,
      customColorValue={},
      customColorKey="",
      customLabelValue={},
      customLabelKey="",
      customLabelColorValue={},
      customLabelBackgroundColorValue={},
      showCustomLabel=false
    },
    ref
  ) => {
    const NoDataRender = noDataRender;
    const [pageNumber, setPageNumber] = useState(initialPageNumber || 1);
    const [pageNumberRad, setPageNumberRad] = useState(initialPageNumber || 1);
    const [totalPagesCount, setTotalPagesCount] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
          setScrollPosition(window.scrollY || document.documentElement.scrollTop);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

    const dispatch = useDispatch();

    useEffect(() => {
      if (!rows && shouldGetRowActions) {
        if (isActiveFirst) {
          dispatch(getRowsAction(pageNumber));
          return;
        }
        dispatch(getRowsAction(pageNumberRad));
      }
      if (resetDataAction) return () => dispatch(resetDataAction());
    }, []);

    useEffect(() => {
      if (!isNil(totalItems)) {
        setTotalPagesCount(Math.ceil(totalItems / 10));
      }
    }, [totalItems]);

    const onChangePageNumber = (newPageNumber) => {
      if (isActiveFirst) {
        setPageNumber(newPageNumber);
      } else {
        setPageNumberRad(newPageNumber);
      }
      dispatch(getRowsAction(newPageNumber));
    };

    if (!columns) return null;

    return (
      <div ref={ref}>
          {
        showHorizontalScrollChevrons && rows?.length>0 && <ChevronContainer>
        {
         !isAtStart &&   <ChevronContainerLeft top={`${rows.length ===1?'380px' : rows.length<3?'420px': window.innerHeight< 1000? '50%':'480px'}`} isAtStart={isAtStart} isAtEnd={isAtEnd} scrollPosition={scrollPosition} rows={rows} onClick={handleScrollLeft}>
         <IconChevronLeft style={{opacity: isAtStart ? 0.5 : 1, }} />
       </ChevronContainerLeft>
        }
        {
        !isAtEnd &&    <ChevronContainerRight top={`${rows.length ===1?'380px' : rows.length<3?'420px': window.innerHeight<1000? '50%': '480px'}`}isAtStart={isAtStart} isAtEnd={isAtEnd}  scrollPosition={scrollPosition} rows={rows} onClick={handleScrollRight}>
        <IconChevronRight style={{ opacity: isAtStart?1 : isAtEnd ? 0.5 : 1,     }} />
      </ChevronContainerRight>
        }
     
      </ChevronContainer>
      }
        <TableContainer>
          <thead>
            <tr>
              {columns.map((column, i) => (
                <TableHeaderCell
                  key={JSON.stringify(column + i)}
                  width={column?.width ? column.width : ''}
                  align={column?.align ? column.align : 'center'}
                  paddingLeft={column?.paddingLeft}
                >
                  {column.displayName ? column.displayName() : column.name}
                  {fromRecoValidation? column?.enableCheckBox && rows && rows?.length>0 && !isLoadingRows &&    <div style={{position:'relative'}}>
                      {showOnlyLabel? <label style={{ display:'flex', top: checkBoxAtStart? '-8px':'0px', marginBottom:'0px', color: allSelected? "#00AEEF": "#9FA2B4", fontWeight:600, fontSize:'12px', position: checkBoxAtStart? 'absolute':'relative'}}>
                       Completed
                     </label> :<label style={{ display:'flex', top: checkBoxAtStart? '-8px':'0px', marginBottom:'0px', color: allSelected? "#00AEEF": "#9FA2B4", fontWeight:600, fontSize:'12px', position: checkBoxAtStart? 'absolute':'relative'}}>
                      <input style={{marginRight:'8px'}} type="checkbox" checked={allSelected}  onChange={(e)=>selectAllCb(rows,e)} />
                       Mark as Completed
                     </label> }
                     </div> : column?.enableCheckBox && rows && rows?.length>0 && !isLoadingRows &&    <div style={{position:'relative'}}>
                      <label style={{ display:'flex', top: checkBoxAtStart? '-8px':'0px', marginBottom:'0px', color: allSelected? "#00AEEF": "#9FA2B4", fontWeight:600, fontSize:'12px', position: checkBoxAtStart? 'absolute':'relative'}}>
                      <input style={{marginRight:'8px'}} type="checkbox" checked={allSelected}  onChange={(e)=>selectAllCb(rows,e)} />
                      {
                      allSelected? `${selectedIds?.length} ${column?.checkboxLabel || 'Recommendations'} Selected` : `Select All (${rows?.length})`
                      }
                     </label> 
                     </div>
                 }
                 {column?.enableSort && <>
                  <ArrowUpwardIconStyled style={{fontSize:'18px'}} active={activeSortColumn === column?.sortKey + ' ' + 'Asc'}  onClick={()=>{
                    if(activeSortColumn ===  column?.sortKey + ' ' + 'Asc'){
                      setActiveSortColumn(null)
                      return
                    }
                    setActiveSortColumn(column?.sortKey + ' ' + 'Asc')
                  }
                  }/>
                  <ArrowDownwardIconStyled  style={{fontSize:'18px'}} active={activeSortColumn === column?.sortKey + ' ' + 'Desc'} onClick={()=>{
                    if(activeSortColumn ===  column?.sortKey + ' ' + 'Desc'){
                      setActiveSortColumn(null)
                      return
                    }
                   setActiveSortColumn(column?.sortKey + ' ' +'Desc')
                  }
                  }/>
                  </> }  
                
                </TableHeaderCell>
              ))}
              {renderButtonsColumn && <TableHeaderCell />}
            </tr>
          </thead>
          {!isLoadingRows && (
            <tbody>
              <Fragment>
                {rows &&
                  rows.map((row, idx) => (
                    <TableRow 
                    content={showCustomLabel?customLabelValue[row[customLabelKey]]: ''}
                    color={showCustomColor? customColorValue[row[customColorKey]]:''}
                    labelColor={showCustomLabel? customLabelColorValue[row[customLabelKey]]: ''}
                    labelBackgroundColor={ showCustomLabel? customLabelBackgroundColorValue[row[customLabelKey]]:''}
                    isDisabled={ disabledRow? !row[disabledRow] : false}
                    onClick={()=>{
                      cb(row) 
                       }} key={JSON.stringify(row + idx)}
                        
                         >
                      {columns.map((column, i) => {
                        return (
                          <TableCell
                            key={JSON.stringify(column + i)}
                            align={column.align ? column.align : 'center'}
                            paddingLeft={column?.paddingLeft}
                          >
                            {column.displayCell
                              ? column.displayCell(row[column.key], row, idx,rows)
                              : row[column.key]}
                          </TableCell>
                        );
                      })}
                      {renderButtonsColumn && isActiveFirst && (
                        <TableCell>{renderButtonsColumn(row)}</TableCell>
                      )}
                    </TableRow>
                  ))}
              </Fragment>
            </tbody>
          )}
        </TableContainer>
        {!isLoadingRows &&
          rows &&
          rows.length === 0 &&
          (noDataRender ? (
            <NoDataRender />
          ) : (
            <NoDataContainer>No data</NoDataContainer>
          ))}
        {isLoadingRows && (
          <SpinnerContainer>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </SpinnerContainer>
        )}
      </div>
    );
  }
);

export default Table;
