import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Flex from "../../../../components/_styles/Flex";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { ReactComponent as StatusIcon } from "../../../../assets/statusIcon.svg";
import { ReactComponent as DownArrow } from "../../../../assets/dropdown-outline-white.svg";
import searchIcon from "../../../../assets/search.svg";
import loadMoreIcon from "../../../../assets/load-more.svg";
import FaxTemplateModal from "../FaxCreationDialog";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  getFaxRecords,
  getIncommingFax,
  getRecentIncomingMessages,
  getRecommendationByPatientId,
} from "../../../../actions/radminUploads";
import { MessagesFilter } from "../MessagesFilter";
import ViewReportModal from "../ViewReportModal";
import MessagePreview from "../MessagePreview";
import MessageContent from "../ShowMessageContent";
import useDebounce from "./useDebounce";

const SearchBoxContainer = styled.div`
  position: relative;
  width: 320px;
  margin-bottom: 13px;
`;
const SearchBox = styled.input`
  font-size: 14px;
  width: 100%;
  margin-top: 15px;
  border: 1px solid #dfe0eb;
  padding: 9px 14px 9px 39px;
  border-radius: 5px;
  height: 38px;
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const PhoneNumberLink = styled.a`
  font-family: Roboto Condensed;
  line-height: 18px;
  font-size: 14px;
  text-decoration: none;
  color: #3f4857;
`;

const Info = styled.p`
  font-family: Roboto Condensed;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 0 8px 0 0;
`;

const RecentMessagesContainer = styled.div`
  margin-top: 25px;
  padding-bottom: 34px;
  // height: 1rem;
  width: 100%;
  align-items: center;
  margin: auto;
`;

const Badge = styled(Flex)`
  width: fit-content;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 600;
  font-family: Open Sans;
  white-space: nowrap;
  padding: 5px 16px 5px 16px;
  color: ${(props) => (props.status === "non_mature" ? "black" : "white")};
  background: ${(props) => {
    switch (props.status) {
      case "coming_due":
        return "#FFD57C";
      case "acknowledged":
        return "#00AEEF";
      case "overdue":
        return "#F73B3B";
      case "unsubscribed":
        return "#F73B3B";
      case "unresponsive":
        return "#808080";
      case "needs_prior_auth":
        return "#DD6ECD";
      case "needs_prescription":
        return "#FF5F65";
      case "non_compliant":
        return "#6f42c1";
      case "rx_received":
        return "#186EF0";
      case "navigator_closed":
        return "#A7A9AD";
      case "completed_elsewhere":
        return "#6F52ED";
      case "patient_deceased":
        return "#2E2F30";
      case "scheduled_not_completed":
        return "#FF7A00";
      case "not_clinically_necessary":
        return "#FFD57C";
      case "adherent":
        return "#64C67C";
      case "non_mature":
        return "2px solid #DFE0EB";
      case "expired":
        return "2px solid #6F42C1";
      case "Not available":
        return "none";
      default:
        return "#00AEEF";
    }
  }};
`;

const Loader2 = styled(CircularProgress)`
  width: 30px !important;
  height: 30px !important;
`;

const MessagesContainer = styled.div`
  height: 33rem;
  overflow: auto;
  padding-right: 10px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const StatusBadge = ({
  status,
  onClick,
  styles = {},
  dropDown = true,
}) => (
  <Badge onClick={onClick} status={status} style={styles}>
    {status?.replaceAll("_", " ")}
    {!dropDown &&
      (status === "adherent" ||
        status === "scheduled_not_completed" ||
        status === "rx_received") && (
        <StatusIcon style={{ marginLeft: "10px" }} />
      )}
    {dropDown && <DownArrow style={{ marginLeft: "10px" }} />}{" "}
  </Badge>
);

const Main = ({
  currentFaxNumber,
  setCurrentFaxNumber,
  isActiveFirstFax,
  setActiveIndex,
  providerData,
}) => {
  const incommingFax = useSelector((state) => state.radAdmin.faxes.faxResponse);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({
    visibility: false,
    data: {},
  });

  const location = useLocation();

  const history = useHistory();
  const [page, setPage] = useState(1);
  const [faxContent, setFaxContent] = useState("");
  const [loadingMoreOptions, setLoadingMoreOptions] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [searchQuery, setSearchQuery] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("fax_number") || "";
  });
  const [currentActiveTab, setCurrentActiveTab] = useState(() => {
    const params = new URLSearchParams(location.search);
    return params.get("type") || "sms&email";
  });

  const mounted = useRef();
  const [showMessageContent, setShowMessageContent] = useState(false);



  useEffect(() => {
    if (incommingFax?.data) {
      if (incommingFax?.data.length > 0) {
        setCurrentFaxNumber({
          text: incommingFax?.data?.[0]?.message,
          name:
            incommingFax?.data?.[0]?.first_name +
            " " +
            incommingFax?.data?.[0]?.last_name,
          phone: incommingFax?.data?.[0]?.phone_number,
          id: incommingFax?.data?.[0]?.id,
          ref_id: incommingFax?.data?.[0]?.unique_id,
        });
      }
      setLoading(false);
    }
  }, [page, incommingFax?.data]);

  useEffect(() => {
    if (currentFaxNumber?.id) {
      dispatch(getFaxRecords(currentFaxNumber?.id, 1));
      dispatch(
        getRecommendationByPatientId(
          currentFaxNumber?.id,
          1,
          "report__referring_physician"
        )
      );
    }
  }, [currentFaxNumber?.id]);


  const handleInputChange = (e) => {
    const value = e.target.value;
    window.analytics.track("Message-Incoming-Search-Click", { search: value });
    setSearchQuery(value);
    setLoading(true);
    setCurrentFaxNumber("");
  };

  const updateURLParams = (paramsToUpdate) => {
    const params = new URLSearchParams(location.search);

    Object.keys(paramsToUpdate).forEach((key) => {
      if (paramsToUpdate[key]) {
        params.set(key, paramsToUpdate[key]);
      } else {
        params.delete(key);
      }
    });

    history.replace({ search: params.toString() });
  };

  const debouncedSearchQuery = useDebounce(searchQuery, 800);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    } else {
      updateURLParams({ fax_number: debouncedSearchQuery });
      dispatch(getIncommingFax(1, debouncedSearchQuery));
    }
  }, [debouncedSearchQuery]);

  return (
    <div>
      {
        <>
          <InfoWrapper>
            <Info>Your Fax Number</Info>
            <PhoneNumberLink>{providerData?.fax}</PhoneNumberLink>
          </InfoWrapper>
          <SearchBoxContainer>
            <SearchBox
              ref={mounted}
              placeholder="Search by Unique ID or Fax Number"
              value={searchQuery}
              onChange={handleInputChange}
            />
            <img
              style={{ position: "absolute", left: "10px", top: "25px" }}
              src={searchIcon}
            />
          </SearchBoxContainer>

          {incommingFax?.isLoading && page ===1 ? (
            <LoaderContainer>
              <Loader2 />
            </LoaderContainer>
          ) : incommingFax?.data?.length == 0 ? (
            <RecentMessagesContainer style={{ width: "225px" }}>
              <MessagePreview
                message={{
                  text: "You have no new fax",
                }}
                cb={() => {}}
              ></MessagePreview>
            </RecentMessagesContainer>
          ) : (
            <RecentMessagesContainer>
              <MessagesContainer>
                {incommingFax?.data?.map((itm, idx) => {
                  let patient_phone_number = itm?.phone_number || "";
                  let id = itm.id;
                  let fax = itm.fax || "";

                  return (
                    <MessagePreview
                      isActiveFirstFax={isActiveFirstFax}
                      cb={(obj) => {
                        setActiveIndex(
                          incommingFax?.data.findIndex(
                            (itm) => itm.id === obj.id
                          )
                        );
                        setCurrentFaxNumber(obj);
                      }}
                      key={id}
                      message={{
                        text: fax,
                        name: itm.first_name + " " + itm.last_name,
                        phone: patient_phone_number,
                        id: itm.id,
                        ref_id: itm?.unique_id,
                      }}
                      lastMessageAt={itm?.last_fax_at || ""}
                      currentChatNumber={currentFaxNumber}
                    />
                  );
                })}
              </MessagesContainer>
            </RecentMessagesContainer>
          )}
          {incommingFax?.data?.length !== incommingFax?.totalItems &&
            incommingFax?.data?.length > 0 && (
              <div style={{ width: "100%", marginBottom: "82px" }}>
                <button
                  disabled={incommingFax?.isLoading || loadingMoreOptions}
                  onClick={() => {
                    window.analytics.track("Message-Incoming-Load-More-Click");
                    setLoadingMoreOptions(true);
                    dispatch(getIncommingFax(page + 1, searchQuery)).then(
                      (res) => setLoadingMoreOptions(false)
                    );
                    setPage((prev) => prev + 1);
                  }}
                  style={{
                    border: "2px solid #00AEEF",
                    color: "#00AEEF",
                    padding: "10px 88px 10px 88px",
                    background: "white",
                    borderRadius: "5px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    {loadingMoreOptions ? (
                      <>LOADING...</>
                    ) : (
                      <>
                        LOAD MORE
                        <img src={loadMoreIcon} />
                      </>
                    )}
                  </span>
                </button>
              </div>
            )}
        </>
      }

   
    </div>
  );
};

export default memo(Main);
